import { ConfirmDialog } from '../Components/ConfirmDialog'
import { useEffect, useState } from 'react'
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_ICONS } from '../Components/Icons';

export const ContactPersonCard = ({ companyContactPerson, onEditHandler, onDeleteHandler, readonly }) => {
	const currentUser = useCurrentUser();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	const renderContent = () => {
		const { jobDescription, phone, mobile, fax, mail } = companyContactPerson.contactPerson
		return <div className="card-content flex align-content-end flex-column h-10rem py-1 px-2 overflow-x-auto">
			<div className={jobDescription ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.JOBPROFILE} /></div><div className='col'>{jobDescription}</div></div>
			<div className={phone ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col'>{phone}</div></div>
			<div className={mobile ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col'>{mobile}</div></div>
			<div className={fax ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.FAX} /></div><div className='col'>{fax}</div></div>
			<div className={mail ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col'>{mail}</div></div>
		</div>
	}

	const renderTitle = () => {
		return <div className="flex title">
			<div className='flex-grow-1 font-bold '>
				{companyContactPerson.contactPerson.lastname + ', ' + companyContactPerson.contactPerson.firstname + " - " + companyContactPerson.contactType.display}
			</div>
			<div className={currentUser.permissions === 'READER' || readonly ? 'hidden' : "flex justify-content-end mt-1 gap-2 h-1rem w-3rem"}>
				<i className={ENUM_ICONS.PENCIL + 'text-white'} onClick={() => { onEditHandler(companyContactPerson.id) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { setDisplayConfirmDialog(true) }} />
			</div>
		</div>

	}

	return (<>
		<ConfirmDialog title='Person entfernen' message={`Sind Sie sicher,\nPerson ${companyContactPerson.contactPerson.lastname}, ${companyContactPerson.contactPerson.firstname}\nvon dem Unternehmen zu entfernen?'`} labelOk='Ja'
			handleOnClick={() => onDeleteHandler(companyContactPerson)} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

		<div className="eaa-card contactperson-card shadow-2">
			{renderTitle()}
			{renderContent()}
		</div>
	</>)
}