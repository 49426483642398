import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { CompanyForm } from "../Companies/CompanyForm";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { TemplateDataView } from "../Components/TemplateDataView";
import client, { findData, getData, patchData } from "../feathers";
import { ENUM_ROUTES, ROUTES } from "../Navigation/Routes";
import { useBreadcrumbsAdd, useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useUserSwitchMode } from "../Persistence/CurrentUserContext";
import { DEFAULT_EAACASES_FILTER, ENUM_DATAVIEWS, useDataViewFilterUpdate } from '../Persistence/DataViewFilterContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { logDefault } from "../Utils/logger";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { format, addDays, isAfter, isBefore } from "date-fns";

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.REMINDERS
const CLASSNAME = 'Dashboard';

export const Dashboard = () => {

	const currentUser = useCurrentUser();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const addBreadcrumb = useBreadcrumbsAdd();
	const [eaaCases, setEaaCases] = useState([])
	const [remindersList, setRemindersList] = useState([])
	const [reminder, setReminder] = useState([])
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialogReminderDone, setDisplayConfirmDialogReminderDone] = useState(false);

	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [company, setCompany] = useState();
	const [formHasErrors, setFormHasErrors] = useState();

	useEffect(() => {
		if (configurationsList.CaseStatusList) {
			getData('users', currentUser.id).then((user) => {
				const statusList = configurationsList.CaseStatusList.map((status) => {
					return status.alias === 'INACTIVE' || status.alias === 'CLOSED' ? null : { status, count: 0 }
				}).filter(entry => entry !== null)

				if (user.caseUsers) {
					user.caseUsers.forEach((caseUser) => {
						if (caseUser && caseUser.roleType && caseUser.roleType.alias === 'R') {
							const status = statusList.find(entry => entry.status.alias === caseUser.eaacase.status)
							if (status) {
								status.count = ++status.count;
							}
						}
					})
				}
				setEaaCases(statusList)
			})
		}

	}, [configurationsList])

	useEffect(() => {
		getReminders();

	}, [])

	const getReminders = () => {
		// query mit date -> date: { $and: [{ $gte: startDate }, { $lte: endDate }] },
		const query = {
			active: 1,
			reminded: 0,
			userId: currentUser.id,
			$sort: { date: 1 }
		}
		findData('reminders', query).then(async (reminders) => {

			for (let reminder of reminders) {
				if (reminder.serviceName === 'eaa-cases') {
					reminder.referenceName = await getData(reminder.serviceName, reminder.serviceId).then((eaaCase) => {
						return eaaCase.company.name + " | " + eaaCase.name;
					})

				} else if (reminder.serviceName === 'job-openings') {
					reminder.referenceName = await getData(reminder.serviceName, reminder.serviceId).then((jobOpening) => {
						return jobOpening.company.name + " | " + jobOpening.description;
					})

				} else {
					reminder.referenceName = reminder.name
				}

			}
			logDefault(CLASSNAME, 'info', 'get reminders', reminders)
			setRemindersList(reminders)
		})
	}

	useEffect(() => {
		if (remindersList) {
			const service = client.service('reminders');
			service.removeListener('created')
			service.removeListener('patched')
			service.on('created', getReminders);
			service.on('patched', getReminders);
		}
	}, [remindersList])

	const onClickEAACase = (eaaStatus) => {
		const responsible = { ...DEFAULT_EAACASES_FILTER.responsible, value: [currentUser.lastname + ', ' + currentUser.firstname] };
		const status = { ...DEFAULT_EAACASES_FILTER.status, value: [eaaStatus.alias] };
		const filters = { ...DEFAULT_EAACASES_FILTER, responsible, status }
		dataViewFilterUpdate(ENUM_DATAVIEWS.EAACASES, filters);
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fallverwaltung', route: ENUM_ROUTES.EAACASES }] })
	}

	const renderEaaCases = () => {
		return eaaCases.map((eaaCase) => {
			return <div key={'eaacase_' + eaaCase.status.alias} onClick={() => { onClickEAACase(eaaCase.status) }} className="flex grid  align-content-start flex-grow-1 h-7rem justify-content-center surface-0 shadow-2 m-2 p-2 border-radius-sm  cursor-pointer">
				<div className="col">
					<div className="flex flex-column">
						<div className="text-lg">{eaaCase.status.display}</div>
						<div className={"mt-2 font-bold text-xl " + ('color-text-' + eaaCase.status.color)} >{`${eaaCase.count} ${eaaCase.count === 1 ? 'Fall' : 'Fälle'}`}</div>
						<div className="flex text-base w-full justify-content-end">Ansehen</div>
					</div>
				</div>
			</div>
		})
	}

	const handleEditReminder = async (id) => {
		await getData('reminders', id).then((reminder) => {
			setReminder(reminder);
			setDisplayReminderDialog(true);
		})
	}

	const handleSetReminderDone = async (reminder) => {
		setReminder(reminder);
		setDisplayConfirmDialogReminderDone(true);
	}

	const handleOpenRemindedObject = async (serviceName, id) => {
		const route = serviceName === 'companies' ? ROUTES['COMPANIES'] : (serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : ROUTES['JOBPROFILES'])
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}
	const onReminderDone = async () => {
		reminder.reminded = '1'
		await patchData('reminders', reminder)
	}

	const patchCompany = async () => {
		company.regionId = company.region.id
		await patchData('companies', company, currentUser).then((company) => {
			updateBreadcrumbs({ home: { icon: ROUTES.COMPANIES.icon }, items: [{ label: ROUTES.COMPANIES.label, route: ROUTES.COMPANIES.route }, { label: '', route: ROUTES.COMPANIES.route + '/' + company.id }] })
		})
	}

	const renderContextMenu = (rowData) => {
		return <div className='open-icon flex justify-content-between h-1rem'>
			<i className={ENUM_ICONS.EYE} onClick={() => handleOpenRemindedObject(rowData.serviceName, rowData.serviceId)} />
			{userSwitchMode ? '' : <i className={ENUM_ICONS.PENCIL + 'edit-icon'} onClick={() => handleEditReminder(rowData.id)} />}
			{userSwitchMode ? '' : <i className={ENUM_ICONS.CHECK + 'check-icon'} onClick={() => handleSetReminderDone(rowData)} />}
		</div>
	}

	const renderReminderDate = (rowData) => {
		const date = addDays(new Date(rowData.millisecondsDate), 3)
		const icon = isBefore(date, new Date()) ? <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.BELL} size={ENUM_AWESOMEICON_SIZE.X1} className='text-500' /> : null
		return <div className='flex'>
			<div className='flex flex-grow-1 pt-1'>{format(rowData.millisecondsDate, 'yyyy-MM-dd')}</div>
			<div className='flex col-2'>{icon}</div>
		</div>

	}

	const columnsReminders = [
		{ field: "serviceName", sortable: true, filter: "serviceName", header: "Art", className: 'w-4rem', body: 'renderListIcon' },
		{ field: "referenceName", sortable: true, filter: "referenceName", header: "Name", className: 'w-30rem' },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Datum", className: 'w-11rem', body: renderReminderDate },
		{ field: "description", sortable: true, filter: "description", header: "Notiz", className: 'w-30rem' },
		{ className: 'w-6rem', body: renderContextMenu },
	]

	return (
		<div className='mx-auto eaa-dataView select-none'>
			{!reminder ? '' :
				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header={reminder.serviceName === 'companies' ? 'Unternehmen' : 'Fall'} name={reminder.name} serviceId={reminder.serviceId} serviceName={reminder.servcieName} userId={reminder.userId} reminderId={reminder.id} />
			}
			<ConfirmDialog title='Wiedervorlage abschliessen' message={`<span>Wiedervorlage <br/><b>${reminder.name}</b><br/> als bearbeitet abschliessen?</span>`} labelOk='Ja'
				handleOnClick={onReminderDone} displayConfirmDialog={displayConfirmDialogReminderDone} setDisplayConfirmDialog={setDisplayConfirmDialogReminderDone} />

			<Dialog header='Neues Unternehmen anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-company-details" className='eaa-inputdialog'  >
				<CompanyForm editmode={true} setFormHasErrors={setFormHasErrors} setCompanyForm={setCompany} handleSelectExistingCompany={() => { setDisplayCompanyDialog(false) }} setCompany={setCompany} key={'CompaniesForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchCompany} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Neu anlegen' />
				</div>
			</Dialog>
			<div className="flex justify-content-between flex-wrap w-full pt-2">
				<div className="flex  font-semibold text-xl">
					{`Willkommen zurück ${currentUser.firstname} ${currentUser.lastname}`}
				</div>
				<div className="flex justify-content-end gap-3">
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { updateBreadcrumbs({ home: { icon: ROUTES['EAACASES'].icon }, items: [{ label: ROUTES['EAACASES'].label, route: ROUTES['EAACASES'].route }, { label: 'Neuen Fall anlegen', route: ROUTES['EAACASES'].route + '/new' }] }) }} className='flex  text-white' label='Neuen Fall anlegen' />
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { setDisplayCompanyDialog(true) }} className='flex text-white ' label='Neues Unternehmen anlegen' />
				</div>
			</div>

			<div className="card shadow-2 p-4 mt-2 mb-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre Übersicht</div>
				<div className="flex justify-content-center flex-wrap gap-4">
					{renderEaaCases()}
				</div>
			</div>

			{remindersList.length === 0 ? '' : <div className="card shadow-2 p-4 my-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre Wiedervorlagen</div>
				<div style={{ height: 'calc(100vh - 510px)' }}>
					<TemplateDataView key='datatable-reminders' scrollHeight={'calc(100vh - 550px)'} classNameDataTable='flex w-full' dataTableList={remindersList} colums={columnsReminders} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} />
				</div>
			</div>}


		</div>
	)

}