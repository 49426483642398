import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";

export enum ENUM_ROUTES {
	JOBPROFILES = 'Stellenprofile', STATISTICS = 'Statistik', PROVIDERACTIVITIES = 'Traegeraktivitaeten', COMPANIES = 'Unternehmen', REQUESTDISTRIBUTION = 'Aufgabenverteilung',
	EAACASES = 'Fallverwaltung', DASHBOARD = 'Dashboard', ADDRESSES = 'Adressen', USERADMINISTRATION = 'Nutzerverwaltung', SETTINGS = 'Einstellungen', USERPROFILE = 'Benutzerprofil'
};

export const ROUTES = {
	JOBPROFILES: { label: 'Stellenprofile', icon: ENUM_ICONS.JOBPROFILE, route: ENUM_ROUTES.JOBPROFILES, service: 'job-openings', labelNew: '' },
	STATISTICS: { label: 'Statistik', icon: ENUM_ICONS.STATISTICS, route: ENUM_ROUTES.STATISTICS },
	PROVIDERACTIVITIES: { label: 'Trägeraktivitäten', awesomeIcon: ENUM_AWESOMEICON.PROVIDERACTIVITIES, icon: ENUM_ICONS.PROVIDERACTIVITIES, route: ENUM_ROUTES.PROVIDERACTIVITIES, service: 'provideractivities' },
	COMPANIES: { label: 'Unternehmen', icon: ENUM_ICONS.COMPANY, route: ENUM_ROUTES.COMPANIES, service: 'companies' },
	EAACASES: { label: 'Fallverwaltung', icon: ENUM_ICONS.EAACASE, route: ENUM_ROUTES.EAACASES, service: 'eaa-cases', labelNew: 'Neuen Fall anlegen' },
	DASHBOARD: { label: 'Dashboard', icon: ENUM_ICONS.DASHBOARD, route: ENUM_ROUTES.DASHBOARD },
	ADDRESSES: { label: 'Adressen', icon: '', route: ENUM_ROUTES.ADDRESSES },
	USERADMINISTRATION: { label: 'Nutzerverwaltung', awesomeIcon: ENUM_AWESOMEICON.USERADMINISTRATION, icon: ENUM_ICONS.USERADMINISTRATION, route: ENUM_ROUTES.USERADMINISTRATION },
	SETTINGS: { label: 'Einstellungen', icon: ENUM_ICONS.SETTINGS, route: ENUM_ROUTES.SETTINGS },
	USERPROFILE: { label: 'Benutzerprofil', icon: ENUM_ICONS.USER, route: ENUM_ROUTES.USERPROFILE },
	REQUESTDISTRIBUTION: { label: 'Aufgabenverteilung', icon: ENUM_ICONS.REQUESTDISTRIBUTION, route: ENUM_ROUTES.USERPROFILE },
}