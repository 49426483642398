import { useEffect, useState } from 'react';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { useUserList } from "../Persistence/UserContext"
import { getData, patchData } from '../feathers';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { UserForm } from './UserForm';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserUpdate, useUserSwitchMode, useUserSwitchModeUpdate } from '../Persistence/CurrentUserContext';
import { UserProfile } from './UserProfile';
import { ChangePassword } from '../Login/ChangePassword';
import { useNavigate } from 'react-router-dom';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { ENUM_DATAVIEWS, useDataViewFilter, useDataViewFilterUpdate, useDataViewSort, useDataViewSortUpdate } from '../Persistence/DataViewFilterContext';
import { paginatorTemplate } from '../Utils/paginatorTemplate';


const mapStatus = { '1': 'color-tag-green', '0': 'color-tag-grey' };
const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.USERADMINISTRATION
export const UserAdministration = () => {
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const currentUser = useCurrentUser();
	const setUserSwitchMode = useUserSwitchModeUpdate();
	const userSwitchMode = useUserSwitchMode();
	const setCurrentUser = useCurrentUserUpdate();
	const configurationsList = useConfigurationsList();
	const usersList = useUserList();
	const [user, setUser] = useState();
	const [paginationFirst, setPaginationFirst] = useState(0);
	const [paginationRows, setPaginationRows] = useState(15);
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(false);
	const [filters, setFilters] = useState(dataViewFilter ? dataViewFilter[ENUM_DATAVIEWS_VALUE] : null);
	const [sortValue, setSortValue] = useState(dataViewSort ? dataViewSort[ENUM_DATAVIEWS_VALUE] : null);

	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser.permissions !== 'ADMIN' && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const handleSelectUser = async (user) => {
		user.permissions = configurationsList.RolesList.find((entry) => entry.alias === user.permissions)
		setUser(user);
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}
	const handleChangeUser = async (id) => {
		await getData('users', id).then((user) => {
			setUserSwitchMode(user);
		})
	}

	const statusItemTemplate = (option) => {
		return <Tag className={"mr-2 text-white px-3 " + mapStatus[option.alias]} rounded value={option.display} />
	}

	const statusRowFilterTemplate = (options) => {
		return <Dropdown value={options.value} className='w-10rem' optionLabel="display" optionValue="alias" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder='Status wählen' />;
	}
	const sortStatus = (event) => {
		let data = [...usersList];
		data.sort((data1, data2) => {
			const value1 = data1[event.field];
			const value2 = data2[event.field];
			let result = null;

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1.alias === 'string' && typeof value2.alias === 'string')
				result = value1.alias.localeCompare(value2.alias, undefined, { numeric: true });
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
		return data;
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser({ ...rowData }), disabled: currentUser.permissions === 'READER' },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in deaktivieren', icon: ENUM_ICONS.CROSS, command: async () => { await setUser({ ...rowData }); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		} else {
			items.push({ label: 'Nutzer*in reaktivieren', icon: ENUM_ICONS.CHECK, command: async () => { await setUser({ ...rowData }); setDisplayConfirmDialog(true) }, disabled: currentUser.permissions !== 'ADMIN' })
		}
		if (currentUser.isAdmin || userSwitchMode) {
			items.push({ label: 'Identität annehmen', icon: ENUM_ICONS.USER, command: () => handleChangeUser(rowData.id) });
		}

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const listItemTemplate = (option) => {
		return <div>{option.display}</div>
	}
	const permissionFilterElement = (options) => {
		return <Dropdown value={options.value} className='w-17rem' panelClassName='p-2' optionLabel="display" optionValue="alias" options={configurationsList.RolesList} onChange={(e) => { options.filterApplyCallback(e.value) }} itemTemplate={listItemTemplate} placeholder='Rolle wählen' />;
	}

	const renderPermission = (rowData) => {
		const permission = !configurationsList.RolesList ? undefined : configurationsList.RolesList.find((entry) => entry.alias === rowData.permissions)
		return !permission ? rowData.permissions : permission.display
	}

	const renderStatus = (rowData) => {
		const status = listStatus.find(entry => entry.alias === rowData.active);
		return <div className="w-full flex justify-content-between flex-wrap card-container">
			<div className="flex align-items-start">
				<Tag className={"text-white px-3 " + mapStatus[status.alias]} rounded value={status.display} />
			</div>

		</div>
	}

	const renderDialogUserHeader = () => {
		return user && user.id ? 'Nutzerdaten ändern' : 'Neue/r Nutzer*in hinzufügen'
	}

	const patchUser = async (user) => {

		user.organizationId = user.organization.id
		user.permissions = user.permissions.alias
		user.displayname = user.firstname + " " + user.lastname
		await patchData('users', user).then((_user) => {
			if (currentUser.id === _user.id) {
				setCurrentUser(_user)
			}
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = () => {
		setUser(null);
		setDisplayUserDialog(true);
	}

	const onChangeFilter = (e) => {
		const filters = e.filters;
		setDataViewFilter(ENUM_DATAVIEWS_VALUE, filters);
		setFilters(filters);
	}

	const onChangeSort = (e) => {
		setSortValue({ sortField: e.sortField, sortOrder: e.sortOrder })
		setDataViewSort(ENUM_DATAVIEWS_VALUE, { sortField: e.sortField, sortOrder: e.sortOrder })
	}

	return (
		<div className='mx-auto eaa-dataView'>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>
			<Dialog header={renderDialogUserHeader} visible={displayUserDialog} onHide={() => setDisplayUserDialog(false)} id="dialog-user-details" className='eaa-inputdialog' >
				<UserForm user={user} setUser={setUser} setFormHasErrors={setFormHasErrors} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayUserDialog(false)} />
					<Button onClick={() => patchUser(user)} disabled={formHasErrors} className="flex-order-0 ml-3 " label={user && user.id ? 'Speichern' : 'Neu anlegen'} />
				</div>
			</Dialog>
			{currentUser.permissions !== 'ADMIN' && !userSwitchMode ?
				<UserProfile handleEditUser={handleSelectUser} handleChangePassword={handleChangePassword} /> : <>
					<div className="flex justify-content-end flex-wrap card-container">
						<div className="flexalign-self-center justify-content-left  my-2">
							<Button className='flex  text-white' label='Nutzer*in hinzufügen' onClick={onClickNewUser} />
						</div>
					</div>
					<div className="card hidden md:inline-flex w-full" >
						<DataTable emptyMessage='keine Einträge gefunden' removableSort filters={filters} filterDisplay='row' paginator paginatorTemplate={paginatorTemplate} first={paginationFirst} rows={paginationRows} scrollHeight={'calc(100vh - 200px)'} className='w-full' value={usersList} showGridlines stripedRows size="small" responsiveLayout="scroll" dataKey="id" onFilter={onChangeFilter} onSort={onChangeSort} sortField={sortValue.sortField} sortOrder={sortValue.sortOrder} aria-label='Benutzer*innen'>
							<Column field='organization.name' filter sortable header="Organisation" showFilterMenu={false} filterPlaceholder='Organisation suchen' />
							<Column field='displayname' sortable filter showFilterMenu={false} filterPlaceholder="Namen suchen" filterDisplay="row" header="Name" />
							<Column field='permissions' filterField='permissions' filter sortable body={renderPermission} header="Rolle" filterElement={permissionFilterElement} showFilterMenu={false} />
							<Column className='hidden' field='fte' sortable filter header="FTE" body={(rowData) => rowData.fte.toFixed(2)} showFilterMenu={false} />
							<Column field='active' className='w-8rem' sortable filter sortFunction={sortStatus} body={renderStatus} filterElement={statusRowFilterTemplate} header="Status" showFilterMenu={false} />
							<Column field="email" filter header="E-Mail" sortable showFilterMenu={false} filterPlaceholder="E-Mail Adresse suchen" />
							<Column body={renderContextMenu} style={{ width: '30px' }} />
						</DataTable>
					</div>
				</>}

		</div>
	)
}