import { Dropdown } from 'primereact/dropdown';

export const paginatorTemplate = {
	layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
	'RowsPerPageDropdown': (options) => {
		const dropdownOptions = [
			{ label: 15, value: 15 },
			{ label: 30, value: 30 },
			{ label: 50, value: 50 },
			{ label: 100, value: 100 },
			{ label: 200, value: 200 }
		];

		return (
			<>
				<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
				<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
			</>
		);
	},
	'CurrentPageReport': (options) => {
		return (
			<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				{options.first} - {options.last} von {options.totalRecords}
			</span>
		)
	}
};