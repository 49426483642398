import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { useCallbackPrompt } from "../../Utils/useCallbackPrompt";
import UnsafedChangesDialog from "../UnsafedChangesDialog";

export const EditDialog = ({ form, formik, header, message, patchForm, displayRegionDialog, setDisplayDialog }) => {
	const [hasErrors, setHasErrors] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog)

	useEffect(() => {
		setHasErrors(Object.keys(formik.errors).length !== 0)
	}, [formik.errors])

	useEffect(() => {
		setShowDialog(Object.keys(formik.touched).length !== 0)
	}, [formik.touched])

	const onClickSave = () => {
		setShowDialog(false);
		setDisplayDialog(false);
		patchForm();
	}
	const onClickCancel = () => {
		setShowDialog(false);
		setDisplayDialog(false);
	}

	return (<>
		<UnsafedChangesDialog
			// @ts-ignore
			showDialog={showPrompt}
			confirmNavigation={confirmNavigation}
			cancelNavigation={cancelNavigation}
		/>
		<Dialog header={header} visible={displayRegionDialog} onHide={onClickCancel} id="dialog-editdialog" className='eaa-inputdialog' >
			<div className='flex font-light mb-3 p-0 '>{message}</div>
			{form}
			<div className="flex flex-row flex-row-reverse mx-4 my-2">
				<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={onClickCancel} />
				<Button disabled={hasErrors} onClick={onClickSave} className="flex-order-0 ml-3 " label={formik.values.id ? 'Ändern' : 'Neu anlegen'} />
			</div>
		</Dialog>
	</>
	)
}