import React, { useContext, useState, useEffect } from "react";
import { findData, updateListEntryInProvider, updateServiceListener } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const UserContext = React.createContext();

const servicename = 'users';
const listenernames = ['created', 'patched'];
const providername = 'UserProvider';

export function useUserList() {
	return useContext(UserContext);
}

export function UserProvider({ children }) {
	const [list, setList] = useState([]);
	const [listItemChanged, setListItemChanged] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	useEffect(() => {
		if (listItemChanged) {
			updateListEntryInProvider(providername, listItemChanged, list, setList, servicename, false)
			setListItemChanged(null);
		}
	}, [listItemChanged, list])

	useEffect(() => {
		LoggerContext.debug('########### ' + providername + ' useEffect update');
		updateServiceListener(servicename, listenernames, (_listentry) => setListItemChanged(_listentry));
	}, [list])

	useEffect(() => {
		if (mounted) {
			findData(servicename, { isAdmin: { $ne: 1 } }).then((_list) => {
				setList(_list);
				LoggerContext.debug(`########### ${providername} useEffect found:${_list.length}`);
			}).catch((error) => { LoggerContext.error("Error " + providername, error) });
			return () => { LoggerContext.debug(`########### ${providername} ---- unload`); updateServiceListener(servicename, listenernames, null); }
		}
	}, [mounted])

	return (
		<UserContext.Provider value={list}>
			{children}
		</UserContext.Provider>
	)
}