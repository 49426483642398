import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useUserSwitchMode } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useOrganizationsList } from '../Persistence/OrganizationsContext';
import { useRegionsList } from '../Persistence/RegionsContext';
import { emptyOrganization, OrganizationForm, validateOrganization } from './OrginizationForm';
import { emptyRegion, RegionForm, validateRegion } from './RegionForm';
import { emptyTaskarea, validateTaskarea } from './TaskareaForm';

export const Settings = () => {
	const currentUser = useCurrentUser();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const organizationsList = useOrganizationsList();
	const [toggleEntry, setToggleEntry] = useState(false);
	const [displayRegionDialog, setDisplayRegionDialog] = useState(false);
	const [displayOrganizationDialog, setDisplayOrganizationDialog] = useState(false);
	const [displayTaskareaDialog, setDisplayTaskareaDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (currentUser.permissions !== 'ADMIN' && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	/** Regions */
	const formikRegion = useFormik({
		initialValues: { ...emptyRegion },
		validate: validateRegion,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const renderRegionEdit = (rowData) => {
		return <div className='flex justify-content-between'>
			<div className='edit-icon'>	<i className={'flex' + ENUM_ICONS.PENCIL} onClick={() => { formikRegion.setValues(rowData); setDisplayRegionDialog(true) }} /></div>
			<div className='del-icon'><i className={'flex ' + (rowData.active ? ENUM_ICONS.TRASH + 'del-icon' : ENUM_ICONS.REACTIVATE + 'check-icon')} onClick={() => { handleStatusChange(rowData, 'regions', 'Region') }} /></div>
		</div>
	}
	const columnsRegions = [
		{ field: "name", sortable: true, filter: "name", header: "Name", className: '' },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		{ className: 'w-6rem', body: renderRegionEdit },
	]

	/** Organizations */
	const formikOrganization = useFormik({
		initialValues: { ...emptyOrganization },
		validate: validateOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderOrganizationEdit = (rowData) => {
		return <div className='flex justify-content-between'>
			<div className='edit-icon'>	<i className={'flex ' + ENUM_ICONS.PENCIL} onClick={() => { editOrganization({ ...rowData }); }} /></div>
			<div className='del-icon'><i className={'flex ' + (rowData.active ? ENUM_ICONS.TRASH + 'del-icon' : ENUM_ICONS.REACTIVATE + 'check-icon')} onClick={() => { handleStatusChange(rowData, 'organizations', 'Organisation') }} /></div>
		</div>
	}
	const editOrganization = (organization) => {
		organization.orgtype = configurationsList.OrganizationTypesList.find(entry => entry.alias === organization.type)
		organization.ignoreInStatistics = (organization.ignoreInStatistics === 1)
		formikOrganization.setValues(organization);
		setDisplayOrganizationDialog(true)
	}
	const columnsOrganizations = [
		{ field: "ignoreInStatistics", sortable: true, filter: "ignoreInStatistics", header: "Statistik", className: 'w-10rem', body: 'renderListIcon' },
		{ field: "name", sortable: true, filter: "name", header: "Name", className: '' },
		{ field: "type", sortable: true, filter: "type", header: "Typ", className: 'w-20rem' },
		{ className: 'w-6rem', body: renderOrganizationEdit },
	]

	/** Taskareas */
	const formikTaskareas = useFormik({
		initialValues: { ...emptyTaskarea },
		validate: validateTaskarea,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const renderTaskareaEdit = (rowData) => {
		return <div className='flex justify-content-between'>
			<div className='edit-icon'>	<i className={'flex' + ENUM_ICONS.PENCIL} onClick={() => { formikTaskareas.setValues(rowData); setDisplayTaskareaDialog(true) }} /></div>
			<div className='del-icon'><i className={'flex ' + (rowData.active ? ENUM_ICONS.TRASH + 'del-icon' : ENUM_ICONS.REACTIVATE + 'check-icon')} onClick={() => { handleStatusChange(rowData, 'taskareas', 'Bereich') }} /></div>
		</div>
	}
	const columnsTaskareas = [
		{ field: "name", sortable: true, filter: "name", header: "Bereich", className: '' },
		{ field: "tags", sortable: true, filter: "tags", header: "Abkürzungen des Bereichs", className: '' },
		{ field: "active", sortable: true, filter: "active", header: "Status", className: 'w-10rem', body: 'renderTag' },
		{ className: 'w-6rem', body: renderTaskareaEdit },
	]


	const toggleStatus = async () => {
		toggleEntry.active = (toggleEntry.active ? 0 : 1);
		await patchData(toggleEntry.servicename, toggleEntry);
		setDisplayConfirmDialog(false);
	}

	const handleStatusChange = async (entry, servicename, objectName) => {
		const command = (entry.active ? 'deaktivieren' : 'reaktivieren');
		const confirmTitle = objectName + ' ' + command
		const confirmMessage = `Sind Sie sicher,\n ${objectName + ': ' + entry.name}\nzu ${command}?`
		setToggleEntry({ ...entry, servicename, confirmTitle, confirmMessage });
		setDisplayConfirmDialog(true);
	}

	const patchOrganization = async () => {
		const org = { ...formikOrganization.values, type: formikOrganization.values.orgtype.alias, ignoreInStatistics: formikOrganization.values.ignoreInStatistics ? 1 : 0 };
		await patchData('organizations', org).then(() => {
			setDisplayOrganizationDialog(false)
		})
	}
	const patchRegion = async () => {
		await patchData('regions', formikRegion.values).then(() => {
			setDisplayRegionDialog(false)
		})
	}

	return (
		<div className={currentUser.permissions !== 'ADMIN' && !userSwitchMode ? 'hidden' : ''}>
			<div className='mx-auto eaa-dataView flex gap-4'>
				<TabView className="w-full gap-2">
					<TabPanel header="Regionen" >
						<div className='flex flex-column gap-2'>
							<div className='flex justify-content-end'>
								<Button className='' disabled={currentUser.permissions !== 'ADMIN'} label='Neue Region anlegen' onClick={() => { formikRegion.setValues({ ...emptyRegion }); setDisplayRegionDialog(true) }} />
							</div>
							<TemplateDataView key='datatable-regions' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={regionsList} colums={columnsRegions} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS.REGIONS} />
						</div>
					</TabPanel>
					<TabPanel header="Organisationen">
						<div className='flex flex-column gap-2'>
							<div className='flex justify-content-end'>
								<Button className='' disabled={currentUser.permissions !== 'ADMIN'} label='Neue Organisation anlegen' onClick={() => { formikOrganization.setValues({ ...emptyOrganization }); setDisplayOrganizationDialog(true) }} />
							</div>
							<TemplateDataView key='datatable-organizations' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={organizationsList} colums={columnsOrganizations} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS.ORGANIZATIONS} />
						</div>
					</TabPanel>
					<TabPanel header="Bereiche" className={configurationsList.eaaModules && configurationsList.eaaModules.REQUESTDISTRIBUTION !== '1' ? 'hidden' : ''}>
						<div className='flex flex-column gap-2'>
							<div className='flex justify-content-end'>
								<Button className='' disabled={currentUser.permissions !== 'ADMIN'} label='Neuen Bereich anlegen' onClick={() => { formikTaskareas.setValues({ ...emptyTaskarea }); setDisplayTaskareaDialog(true) }} />
							</div>
							<TemplateDataView key='datatable-taskareas' scrollHeight={'calc(100vh - 240px)'} classNameDataTable='flex w-full' dataTableList={organizationsList} colums={columnsTaskareas} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS.TASKAREAS} />
						</div>
					</TabPanel>
				</TabView>
			</div>

			<ConfirmDialog title={toggleEntry.confirmTitle} message={toggleEntry.confirmMessage} labelOk='Ja'
				handleOnClick={toggleStatus} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<EditDialog
				form={<RegionForm formik={formikRegion} key='region-form' />}
				formik={formikRegion}
				patchForm={patchRegion}
				header={formikRegion.values.id ? 'Region ändern' : 'Region neu anlegen'}
				message={formikRegion.values.id ? 'Hier können sie die Region: ' + formikRegion.values.name + ' ändern.' : 'Hier können sie eine Region neu anlegen'}
				setDisplayDialog={setDisplayRegionDialog}
				displayRegionDialog={displayRegionDialog}
			/>
			<EditDialog
				form={<OrganizationForm formik={formikOrganization} key='organization-form' />}
				formik={formikOrganization}
				patchForm={patchOrganization}
				header={formikOrganization.values.id ? 'Organiation ändern' : 'Organiation neu anlegen'}
				message={formikOrganization.values.id ? 'Hier können sie die Organiation: ' + formikOrganization.values.name + ' ändern.' : 'Hier können sie eine Organiation neu anlegen'}
				setDisplayDialog={setDisplayOrganizationDialog}
				displayRegionDialog={displayOrganizationDialog}
			/>
		</div>
	)

}