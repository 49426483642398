import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useUserList } from '../Persistence/UserContext';

export const emptyRelatedUser = { id: null, version: 1, active: 1, user: null, roleType: '', selectionUser: null }

export const RelatedUsersForm = ({ relatedUser, setRelatedUser, setShowDialog, listRelatedUsers, setFormHasErrors, parentId }) => {

	const configurationsList = useConfigurationsList();
	const userList = useUserList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [organization, setOrganization] = useState(null);
	const [relatedUserList, setRelatedUserList] = useState([]);

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.organizationId === organization.id) : userList;
		if (relatedUser && relatedUser.id && relatedUser.user) {
			setRelatedUserList([relatedUser.user]);
		} else {
			formik.setFieldValue('user', null)
			setRelatedUserList(tempList);
		}
	}, [organization, userList])


	const formik = useFormik({

		initialValues: {
			id: null, version: 1, active: 1, user: null, roleType: '', selectionUser: null
		},
		validate: (data) => {
			let errors = {};

			if (!data.roleType) {
				errors.roleType = 'Verantwortlichkeit muss gesetzt sein';
			}
			if (!data.user) {
				errors.user = 'Bearbeiter*in muss gesetzt sein';
			}
			if (data.user && data.roleType && data.roleType.alias === 'R') {
				const existingRelatedUser = listRelatedUsers.find(entry => entry.roleType.alias === data.roleType.alias);
				if (existingRelatedUser && existingRelatedUser.user.id !== data.user.id)
					errors.roleType = 'Verantwortliche Rolle bereits definiert';
			}
			if (data.user) {
				const existingRelatedUser = listRelatedUsers.find(entry => entry.user.id === data.user.id);
				if (existingRelatedUser && !relatedUser.id)
					errors.user = 'Bearbeiter*in hat bereits eine Rolle im Fall';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null

			setFormHasErrors(hasErrors);
			setRelatedUser(data);
			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true
	});

	useEffect(() => {
		formik.setValues(relatedUser);
	}, [])

	const onRelatedUserChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			formik.setFieldValue('selectionUser', e.value.displayname)
			formik.setFieldValue('user', e.value)
		} else {
			formik.setFieldValue('user', null)
			formik.setFieldValue('selectionUser', e.value)
		}
	}

	return (
		<div className='col'>
			<div className="formgrid grid">
				<div className="field col">
					<div className='card mb-1'><div className="font-bold">Bearbeiter*innen</div> </div>
					<div className={relatedUser && parentId ? 'card' : 'hidden'}>
						<div className={(relatedUser.id ? 'hidden' : '') + " field col mr-3 "}>
							<label htmlFor='organization' className='block'>Filter Organisation</label>
							<Dropdown id="organization" showClear filter name="organization" optionLabel="name" value={organization} options={organizationsSelectionList} onChange={(e) => setOrganization(e.value)} autoFocus className={'bg-white w-full'} />
						</div>
						<DropdownChangesChecked filter={true} editmode={!relatedUser || !relatedUser.id} label={relatedUser && relatedUser.id ? 'Benutzer bereits in Liste' : 'Benutzer'} id={'user'} itemLabel={'displayname'} formik={formik} options={relatedUserList} setShowDialog={setShowDialog} handleChange={formik.handleChange} />
						<DropdownChangesChecked editmode={true} label={'Verantwortlichkeit'} id={'roleType'} itemLabel={'display'} formik={formik} options={configurationsList.CaseRoleList} setShowDialog={setShowDialog} handleChange={onRelatedUserChange} />
					</div>
				</div>
			</div>
		</div>


	)
}
