import { useEffect } from 'react';
import { Login } from './Login/login';
import { ChangePassword } from './Login/ChangePassword';
import { Message } from 'primereact/message';
import { useCurrentUser } from './Persistence/CurrentUserContext';
import { useSocketError } from './Persistence/SocketErrorContext';
import { NavigationPage } from './Navigation/NavigationPage';
import { RegionsProvider } from './Persistence/RegionsContext';
import { ConfigurationsProvider } from './Persistence/ConfigurationsContext';
import { locale, addLocale } from 'primereact/api';
import logger, { LoggerContext, LoggerLogin, loglevel, loglevelcontext, loglevellogin } from './Utils/logger';
import { BreadcrumbsProvider } from './Persistence/Breadcrumbs';
import { ErrorBoundary } from 'react-error-boundary'
import { OrganizationsProvider } from './Persistence/OrganizationsContext';
import { DataViewFilterProvider } from './Persistence/DataViewFilterContext';
import { CompaniesProvider } from './Persistence/CompaniesContext';
import { StrgPressedProvider } from './Persistence/StrgPressed';
import { EAACasesProvider } from './Persistence/EAACasesContext';
import { ProviderActivitiesProvider } from './Persistence/ProviderActivitiesContext';
import { UserProvider } from './Persistence/UserContext';
import { JobOpeningsProvider } from './Persistence/JobOpeningsContext';
import { PostcodesProvider } from './Persistence/PostcodesContext';
import { RegionTagsProvider } from './Persistence/RegionTagsContext';
import { TaskareasProvider } from './Persistence/TaskareasContext';
import { TaskareaTagsTagsProvider } from './Persistence/TaskareaTagsContext';

export const Application = () => {
	const currentUser = useCurrentUser();
	const socketError = useSocketError();

	useEffect(() => {
		logger.level = loglevel;
		LoggerContext.level = loglevelcontext;
		LoggerLogin.level = loglevellogin;
		window.onunhandledrejection = event => {
			logger.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
		};
		addLocale('de', {
			firstDayOfWeek: 1,
			dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
			monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			today: 'Tag',
			clear: 'Löschen'
		});
		locale('de')
	}, [])

	const renderPage = () => {
		logger.info("------------ APPLICATION ------------- " + (currentUser ? currentUser.username : ''));
		if (socketError !== null) {
			return <div className="flex p-jc-center p-ai-center" style={{ height: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }}>
				<div className='p-shadow-24 p-as-center ' style={{ width: '400px' }}>
					<Message className='p-6' severity="warn" text={socketError} />
				</div>
			</div>
		} else if (currentUser && currentUser.forceChangePassword === 1) {
			return (
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={false} />
			)
		} else if (currentUser) {
			return <div>
				<CompaniesProvider>
					<ProviderActivitiesProvider>
						<JobOpeningsProvider>
							<EAACasesProvider>
								<UserProvider>
									<OrganizationsProvider>
										<RegionsProvider>
											<PostcodesProvider>
												<RegionTagsProvider>
													<TaskareasProvider>
														<TaskareaTagsTagsProvider>
															<DataViewFilterProvider>
																<ConfigurationsProvider>
																	<BreadcrumbsProvider>
																		<StrgPressedProvider>
																			<ErrorBoundary onError={(error, errorInfo) => console.log("error boundary: ", error, errorInfo)} onReset={() => {
																				// reset the state of your app
																			}}>
																				<NavigationPage />
																			</ErrorBoundary>
																		</StrgPressedProvider>
																	</BreadcrumbsProvider>
																</ConfigurationsProvider>
															</DataViewFilterProvider>
														</TaskareaTagsTagsProvider>
													</TaskareasProvider>
												</RegionTagsProvider>
											</PostcodesProvider>
										</RegionsProvider>
									</OrganizationsProvider>
								</UserProvider>
							</EAACasesProvider>
						</JobOpeningsProvider>
					</ProviderActivitiesProvider>
				</CompaniesProvider>
			</div>
		}
		return <Login />;
	}

	return renderPage()
}
