import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUserList } from '../Persistence/UserContext';


export const ResponsibleMultiSelect = ({ value, onChange, className }) => {

	const userList = useUserList();
	const [filterValue, setFilterValue] = useState('');
	const filterInputRef = useRef();
	const [options, setOptions] = useState();
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (userList) {
			const list = userList.map((user) => {
				return { label: user.lastname + ', ' + user.firstname }
			});
			setOptions(list);
		}

	}, [userList])

	useEffect(() => {
		if (value) {
			const values = value.map(entry => { return { label: entry } })
			setSelected(values)

		} else {
			setSelected([])
		}

	}, [value])

	const itemTemplate = (option) => {
		return option ? option.label : ''
	}

	const filterTemplate = (options) => {
		let { filterOptions } = options;

		return (
			<div className="flex gap-2">
				<InputText value={filterValue} ref={filterInputRef} onChange={(e) => myFilterFunction(e, filterOptions)} />
				<icon className='pi pi-times' onClick={() => myResetFunction(filterOptions)} />
			</div>
		)
	}

	const myResetFunction = (options) => {
		setFilterValue('');
		options.reset();
		filterInputRef && filterInputRef.current.focus()
	}

	const myFilterFunction = (event, options) => {
		let _filterValue = event.target.value;
		setFilterValue(_filterValue);
		options.filter(event);
	}

	const selectedTemplate = (option) => {
		return option ? <div className="flex text-xm ">{option.label}</div> : '';
	}

	const onSelectionChange = (e) => {
		setSelected(e.value);
		if (e.value) {
			onChange(e.value.map(entry => entry.label))
		} else {
			onChange(null)
		}

	}

	const panelFooterTemplate = () => {
		const selectedItems = selected;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> Berater*In ausgewählt.
			</div>
		);
	}

	return (<>
		{(!userList || userList.length === 0) ? 'keine userlist' :
			<MultiSelect value={selected} optionLabel="label" options={options} onChange={onSelectionChange} itemTemplate={itemTemplate} filter selectedItemTemplate={selectedTemplate} className="multiselect-custom" panelFooterTemplate={panelFooterTemplate} placeholder='Berater*In wählen' selectedItemsLabel={(selected ? selected.length : 0) + ' Berater*In ausgewählt'} maxSelectedLabels={3} />
		}
	</>)
}