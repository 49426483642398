import { InputField } from "../Components/InputField"

export const RegionForm = ({ formik }) => {

	return (
		<div className="formgrid grid  m-0 p-0 mr-3">
			<InputField formik={formik} id='name' label='Name' type='text' />
		</div>
	)
}
export const emptyRegion = { id: null, active: 1, version: 1, name: '' }
export const validateRegion = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	return errors;
}
