import { useEffect } from "react";
import { ENUM_ICONS } from "../Components/Icons";
import { useCurrentUser } from "../Persistence/CurrentUserContext";

export const CaseUserCard = ({ caseUser, onEditHandler, onDeleteHandler, disabled }) => {
	const currentUser = useCurrentUser();

	useEffect(() => {
		//console.log('CaseUserCard', disabled)
	}, [disabled])

	const renderContent = () => {
		const { phonenumber, mobilenumber, email } = caseUser.caseuser

		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			<div className={phonenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col '>{phonenumber}</div></div>
			<div className={mobilenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col '>{mobilenumber}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '>{email}</div></div>
		</div>
	}
	const renderTitle = (caseUser) => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{caseUser.caseuser.lastname + ', ' + caseUser.caseuser.firstname}
				</div>
				<div>
					{caseUser.roleType.display}
				</div>
			</div>
			<div className={currentUser.permissions === 'READER' || disabled ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(caseUser) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(caseUser) }} />
			</div>
		</div>
	}

	return (<>
		{!caseUser || caseUser.active === 0 ? '' :
			<div id='caseuser-card' className="eaa-card caseuser-card shadow-2">
				<div>{renderTitle(caseUser)}</div>
				{renderContent(caseUser)}
			</div>
		}
	</>)
}