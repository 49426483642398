import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faRotateLeft, faPersonRays, faUser, faExternalLink, faTrashAlt, faCalendar, faClock, faEye, faPlus, faPlusCircle, faCheck, faCheckSquare, faFolderOpen, faFolder, faTimes, faPhone, faFax, faGraduationCap, faBuilding, faMobile, faEnvelope, faEur, faTag, faThumbTack, faUsers, faCogs, faBan, faLineChart, faHome, faShield, faSitemap, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faBell, faPenToSquare } from '@fortawesome/free-regular-svg-icons'

export const Icon = ({ ENUM_AWESOMEICON, size, className }) => {
	return <FontAwesomeIcon icon={ENUM_AWESOMEICON} size={size || 'xl'} className={className} />
}

export const ENUM_AWESOMEICON_SIZE = { X1: '1x', X2: '2x', X3: '3x', X4: '4x', X5: '5x', XS2: "2xs", XS1: "1xs", XS: "xs", XL: "xl", XL1: "1xl", XL2: "2xl" }

export const ENUM_AWESOMEICON = {
	REACTIVATE: faRotateLeft, TRASH: faTrashAlt, PENCIL: faPenToSquare,
	EXPORT: faExternalLink, CALENDAR: faCalendar, CLOCK: faClock, EYE: faEye,
	PLUS: faPlus, PLUS_CIRCLE: faPlusCircle, CHECK: faCheck, CHECK_SQUARE: faCheckSquare,
	CROSS: faTimes, FOLDER_OPEN: faFolder, FOLDER_OPEN_O: faFolderOpen,
	PHONE: faPhone, FAX: faFax, ENVELOPE: faEnvelope, MOBILE: faMobile,
	JOBPROFILE: faGraduationCap, COMPANY: faBuilding, EAACASE: faFolder, USER: faUser,
	CASE_USER: faShield, EURO: faEur, JOBTYPE: faTag, LOCATION: faThumbTack,
	DASHBOARD: faHome, REQUESTDISTRIBUTION: faSitemap, PROVIDERACTIVITIES: faPersonRays, USERADMINISTRATION: faUserGroup, SETTINGS: faCogs,
	STATISTICS: faLineChart, CASE_DELETE: faBan, COMPANY_DELETE: faBan, BELL: faBell
};

export const ENUM_ICONS = {
	REACTIVATE: ' fa fa-rotate-left ', TRASH: ' fa fa-trash-o ', PENCIL: ' fa fa-pencil-square-o ',
	EXPORT: ' fa fa-external-link ', CALENDAR: ' fa fa-calendar ', CLOCK: ' fa fa-clock-o ', EYE: ' fa fa-eye ',
	PLUS: ' fa fa-plus ', PLUS_CIRCLE: ' fa fa-plus-circle ', CHECK: ' fa fa-check ', CHECK_SQUARE: ' fa fa-check-square-o ',
	CROSS: ' fa fa-times ', FOLDER_OPEN: ' fa fa-folder-open ', FOLDER_OPEN_O: ' fa fa-folder-open-o ',
	PHONE: ' fa fa-phone ', FAX: ' fa fa-fax ', ENVELOPE: ' fa fa-envelope-o ', MOBILE: ' fa fa-mobile-phone fa-lg ',
	JOBPROFILE: ' fa fa-graduation-cap ', COMPANY: ' fa fa-building ', EAACASE: ' fa fa-folder-open ', USER: ' fa fa-user ',
	CASE_USER: ' fa fa-shield ', EURO: ' fa fa-eur ', JOBTYPE: ' fa fa-tag ', LOCATION: ' fa fa-thumb-tack ',
	DASHBOARD: ' fa fa-home ', REQUESTDISTRIBUTION: ' fa fa-sitemap ', PROVIDERACTIVITIES: ' fas fa-person-rays ', USERADMINISTRATION: ' fa fa-users ', SETTINGS: ' fa fa-cogs ',
	STATISTICS: ' fa fa-line-chart ', CASE_DELETE: ' fa fa-ban ', COMPANY_DELETE: ' fa fa-ban '
};
