import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { getData } from "../feathers";
import { LoggerContext } from "../Utils/logger";
import { SocketErrorProvider } from "./SocketErrorContext";

const CurrentUserContext = React.createContext();
const CurrentUserUpdateContext = React.createContext();
const CurrentUserIsAdminContext = React.createContext();
const UserSwitchModeContext = React.createContext();
const UserSwitchModeUpdateContext = React.createContext();

export function useCurrentUser() {
	return useContext(CurrentUserContext);
}
export function useCurrentUserUpdate() {
	return useContext(CurrentUserUpdateContext);
}
export function useCurrentUserIsAdmin() {
	return useContext(CurrentUserIsAdminContext);
}
export function useUserSwitchMode() {
	return useContext(UserSwitchModeContext);
}
export function useUserSwitchModeUpdate() {
	return useContext(UserSwitchModeUpdateContext);
}

export function CurrentUserProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [switchedUser, setSwitchedUser] = useState(null);
	const [isSwitchMode, setIsSwitchMode] = useState(false);

	function setUser(user) {
		if (user) {
			const { id, isAdmin, displayname, roles, permissions, forceChangePassword } = user;
			setIsAdmin(user.isAdmin === 1);
		} else {
			setIsAdmin(false);
			LoggerContext.verbose("############# setCurrentUser ###############");
		}
		setCurrentUser(user);
	}

	async function setSwitchMode(switchUser) {
		await getData('users', currentUser.id).then((user) => {
			if (!user.isAdmin || !switchUser) {
				setIsSwitchMode(false);
				setSwitchedUser(null)
			} else {
				if (switchUser) {
					switchUser.permissions = 'READER';
					switchUser.isSwitchedUser = true;
				}
				setIsSwitchMode(true);
				setSwitchedUser(switchUser)
			}

		})
	}

	return (
		<CurrentUserContext.Provider value={switchedUser || currentUser}>
			<CurrentUserIsAdminContext.Provider value={isAdmin || isSwitchMode}>
				<CurrentUserUpdateContext.Provider value={setUser}>
					<UserSwitchModeContext.Provider value={isSwitchMode}>
						<UserSwitchModeUpdateContext.Provider value={setSwitchMode}>
							<SocketErrorProvider>
								{children}
							</SocketErrorProvider>
						</UserSwitchModeUpdateContext.Provider>
					</UserSwitchModeContext.Provider>
				</CurrentUserUpdateContext.Provider>
			</CurrentUserIsAdminContext.Provider>
		</CurrentUserContext.Provider>
	)
}