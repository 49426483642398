import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CaseEventsSheet } from "../CaseEvents/CaseEventsSheet";
import { CasePersonForm } from "../CasePersons/CasePersonForm";
import { CasePersonSheet } from "../CasePersons/CasePersonSheet";
import { CaseUsersSheet } from "../CaseUsers/CaseUsersSheet";
import { CompanyChangeDialog } from "../Companies/CompanyChangeDialog";
import { CompanySheet } from "../Companies/CompanySheet";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { deleteData, fetchIconsBase64, getData, patchData } from "../feathers";
import { CasePersonsProvider } from "../Persistence/CasePersonsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { EAACaseForm } from "./EAACaseForm";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { ENUM_ICONS } from "../Components/Icons";
import exportToPDF from "../Utils/exportToPDF";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useBreadcrumbsMinus } from "../Persistence/Breadcrumbs";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { BasicSheetTitleLayout } from "../Components/Layouts/BasicSheetTitleLayout";

const sortCaseUser = (eaaCase) => {
	eaaCase.caseUsers.sort((b, a) => {
		if (a.roleType.alias < b.roleType.alias) {
			return -1;
		}
		if (a.roleType.alias > b.roleType.alias) {
			return 1;
		}
		return 0;
	})
}

export const EAACaseSheet = () => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const { eaaCaseId } = useParams();
	const [eaaCase, setEaaCase] = useState();
	const [casePerson, setCasePerson] = useState();
	const [active, setActive] = useState(0);
	const [readonly, setReadonly] = useState(true);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCaseUserDialog, setDisplayCaseUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayEaaCaseDialog, setDisplayEAACaseDialog] = useState(false);
	const [displayCasePersonDialog, setDisplayCasePersonDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		if (eaaCaseId) {
			getData("eaa-cases", eaaCaseId).then((eaaCase) => {
				eaaCase.status = configurationsList.CaseStatusList.find(e => e.alias === eaaCase.status)
				sortCaseUser(eaaCase);
				console.log('eaaCase ', eaaCase);
				setReadonly(eaaCase.status.alias === 'INACTIVE');
				setEaaCase(eaaCase);
			}).catch((error) => {
				setToastParams({ title: 'Fall mit der Nummer "' + eaaCaseId + '" konnte nicht ermittelt werden', message: error.message })
			});
		}
	}, [eaaCaseId])

	const eaaCaseUpdate = async () => {
		await getData("eaa-cases", eaaCase.id).then((eaaCase) => {
			eaaCase.status = configurationsList.CaseStatusList.find(e => e.alias === eaaCase.status)
			//console.log('eaaCaseUpdate readonly: ' + !eaaCase.active, eaaCase)
			setReadonly(eaaCase.status.alias === 'INACTIVE');
			sortCaseUser(eaaCase);
			setEaaCase(eaaCase);
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		});
	}

	const patchEaaCase = async (eaaCase) => {
		await patchData('eaa-cases', eaaCase).then(async (eaaCase) => {
			await eaaCaseUpdate();
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gespeichert werden', message: error.message })
		})
	}
	const patchCasePerson = async () => {
		await patchData('case-persons', casePerson).then(async (casePerson) => {
			eaaCase.casePersonId = casePerson.id
			await patchEaaCase(eaaCase)
			setDisplayCasePersonDialog(false)
		}).catch((error) => {
			setToastParams({ title: 'Fallperson konnte nicht ' + (casePerson.id ? 'geändert' : 'angelegt') + ' werden', message: error.message })
		})
	}

	const renderDocLink = () => {
		let docLink = '';
		if (eaaCase && eaaCase.documentLocation) {
			docLink = 'file:///' + eaaCase.documentLocation;
			try {
				const url = new URL(eaaCase.documentLocation);
				if (url.protocol === "http:" || url.protocol === "https:") {
					docLink = eaaCase.documentLocation
				}
			} catch (error) { }
		}
		return <a href={docLink} target="_blank">{docLink}</a>

	}

	const renderDialogs = () => {
		return <>
			<ConfirmDialog title='Status ändern' message={'Fall' + (readonly ? ' aktivieren?' : ' inaktivieren?')} labelOk='Ja'
				handleOnClick={handleStatusEAACase} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog title='Fall löschen' message={'Wollen Sie den Fall wirklich unwiderruflich löschen?\nAlle angelegten Kontakte zu diesem Fall werden ebenfalls gelöscht.'} labelOk='Ja'
				handleOnClick={handleDeleteEaaCase} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />

			<ToastError toastParams={toastParams} />
			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Fall' name={eaaCase.name + ' - ' + eaaCase.company.name} serviceId={eaaCase.id} serviceName='eaa-cases' userId={currentUser.id} />
			<Dialog header='Falldetails ändern' visible={displayEaaCaseDialog} onHide={() => setDisplayEAACaseDialog(false)} id="dialog-eaacase" className='eaa-inputdialog' >
				<div className='flex font-light mb-3 p-0 '>{'Hier können Sie die Details eines Falles ändern.'}</div>
				<EAACaseForm eaaCase={eaaCase} patchEaaCase={patchEaaCase} setDisplayEAACaseDialog={setDisplayEAACaseDialog} />
			</Dialog>
			<Dialog header='Details zur Fallperson ändern' visible={displayCasePersonDialog} onHide={() => setDisplayCasePersonDialog(false)} id="dialog-caseperson" className='eaa-inputdialog'>
				<div className='flex font-light mb-3 p-0 '>{casePerson && casePerson.personId ? 'Hier können Sie die Details der Person ' + casePerson.personId + ' ändern.' : 'Hier können Sie eine neue Person zum Fall auswählen oder erstellen'}</div>
				<CasePersonsProvider><CasePersonForm eaaCase={eaaCase} handleCasePersonUpdate={setCasePerson} setShowDialog={() => { }} /></CasePersonsProvider>
				<div className="flex flex-row card-container justify-content-end gap-2 mx-3 my-2">
					<Button className="button-cancel" label="Abbrechen" onClick={() => { setDisplayCasePersonDialog(false) }} />
					<Button onClick={patchCasePerson} disabled={false} className="flex-order-0" label={eaaCase.casePerson && eaaCase.casePerson.id ? 'Ändern' : 'Neu hinzufügen'} />
				</div>

			</Dialog>
			<CompanyChangeDialog companyId={eaaCase.companyId} displayCompanyDialog={displayCompanyDialog} setDisplayCompanyDialog={setDisplayCompanyDialog} handleOnCompanyPatched={eaaCaseUpdate} />
		</>
	}

	const listButtons = [
		{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => setDisplayEAACaseDialog(true), disabled: currentUser.permissions === 'READER' },
		{ label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: () => setDisplayReminderDialog(true), disabled: currentUser.permissions === 'READER' }
	]


	const renderContent = () => {
		const maxChar = 200;
		const title = eaaCase ? eaaCase.company.name + eaaCase.name : '';
		return <div className="eaa-maincontent flex flex-column " >
			<BasicSheetTitleLayout
				listButtons={listButtons}
				title={eaaCase ? `${(title.length > maxChar ? eaaCase.name.substring(0, maxChar - eaaCase.company.name.length) + '...' : eaaCase.name)}` : ''}
				subtitle={<><div>{eaaCase ? `${eaaCase.displayId} vom ${format(parseISO(eaaCase.date), 'dd.MM.yyyy')}` : ''}</div>
					<Tag className={'mr-2 text-white px-3 color-tag-' + eaaCase.status.color} rounded value={eaaCase.status.display} /></>} />

			<div className='flex flex-column my-4' >
				<div className='flex flex-wrap justify-content-between grid'>
					<FieldInfo label='Unternehmen' content={eaaCase.company.name} className='col-6' />
					<FieldInfo label='Verantwortliche*r' content={eaaCase.responsible ? eaaCase.responsible.displayname : 'n.n.'} className='col-3' />
					<FieldInfo label='Ersteller*in' content={eaaCase.caseCreator.displayname} className='col-3' />
				</div>
				<div className='flex flex-wrap justify-content-between  grid'>
					<FieldInfo label='Initiiert durch' content={eaaCase.initiatedBy.display} className='col-3' />
					<FieldInfo label='Dateipfad Dokumente' content={renderDocLink()} className='col-9' />
				</div>
				<Divider />

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-1'>
						Beschreibung
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: eaaCase.description }} />
				</div>

				<div className='flex text-1xl m-2'>
					{eaaCase ? <CaseEventsSheet eaaCase={eaaCase} caseEvents={eaaCase.caseEvents} eaaCaseUpdate={eaaCaseUpdate} /> : ''}
				</div>
			</div>
		</div>
	}

	const handleExportEAACase = async () => {
		await exportToPDF('EAACASE', eaaCase, JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
	}
	const handleStatusEAACase = async () => {
		eaaCase.status = { alias: (eaaCase.status.alias === 'INACTIVE' ? 'OPEN' : 'INACTIVE') }
		await patchEaaCase(eaaCase);
	}
	const handleDeleteEaaCase = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData('eaa-cases', eaaCase.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Fall konnte nicht gelöscht werden', message: error.message })
		})
	}

	const renderItems = () => {
		const items = [{ label: 'Fall bearbeiten', icon: ENUM_ICONS.EAACASE, command: () => setDisplayEAACaseDialog(true), disabled: currentUser.permissions === 'READER' || readonly },
		{ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportEAACase },
		{ separator: true },
		{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.TRASH), command: () => setDisplayConfirmDialog(true), disabled: currentUser.permissions === 'READER' }]
		if (currentUser.permissions === 'ADMIN' && eaaCase) {
			items.push({ separator: true })
			items.push({ label: 'Fall unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return <OverlayPanelMenu items={items} />;
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg", component: <CompanySheet readonly={readonly} company={eaaCase.company} onUpdateCompany={eaaCaseUpdate} /> },
			{ index: 1, visible: true, icon: ENUM_ICONS.USER + "fa-lg", component: <CasePersonSheet eaaCase={eaaCase} patchEaaCase={patchEaaCase} /> },
			{ index: 2, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg", component: <CaseUsersSheet eaaCase={eaaCase} hideDivider={true} handleOnCaseUserPatched={eaaCaseUpdate} handleCaseUserEdit={() => setDisplayCaseUserDialog(true)} /> },
		]
		return sideSheets;
	}

	return (!eaaCase ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!eaaCase ? '' : renderContent()}{!eaaCase ? '' : renderDialogs()}</div>}
			overlayMenu={renderItems()}
			sideSheets={getSideSheets()}
		/>
	)
}
