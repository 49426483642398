import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { patchData } from '../feathers';
import { ChangePassword } from "../Login/ChangePassword";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserUpdate } from "../Persistence/CurrentUserContext"
import { UserForm } from './UserForm';

export const UserProfile = () => {
	const currentUser = useCurrentUser();
	const updateCurrentuser = useCurrentUserUpdate();
	const configuratiosList = useConfigurationsList();
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState(false);
	const [user, setUser] = useState();

	useEffect(() => {
		if (currentUser) {
			setUser({ ...currentUser })
		}
	}, [currentUser])

	const handleChangePassword = () => {
		setDisplayPasswordDialog(true);
	}
	const handleEditUser = () => {
		user.permissions = configuratiosList.RolesList.find((entry) => entry.alias === currentUser.permissions)
		setDisplayUserDialog(true)
	}

	const patchUser = async () => {
		const { id, active, version, mobilenumber, phonenumber, firstname, lastname } = user
		await patchData('users', { id, version, active, mobilenumber, phonenumber, firstname, lastname }).then((user) => {
			updateCurrentuser(user);
			setUser(user);
			setDisplayUserDialog(false);
		})
	}

	return (
		<div className='mx-auto eaa-maincontent'>
			<Dialog header={'Passwort ändern: ' + currentUser.displayname} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={true} hideDialog={setDisplayPasswordDialog} />
			</Dialog>
			<Dialog header='Eigene Daten bearbeiten' visible={displayUserDialog} onHide={() => setDisplayUserDialog(false)} id="dialog-user-details" className='eaa-inputdialog' >
				<UserForm user={user} setUser={setUser} setFormHasErrors={setFormHasErrors} profileMode={true} />
				<div className="flex flex-row flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayUserDialog(false)} />
					<Button onClick={patchUser} disabled={formHasErrors} className="flex-order-0 ml-3 " label={user && user.id ? 'Speichern' : 'Neu anlegen'} />
				</div>
			</Dialog>
			<div className='flex justify-content-end mb-4 gap-2'>
				<Button onClick={handleEditUser} icon={ENUM_ICONS.PENCIL} disabled={currentUser.permissions === 'READER'} className='flex text-white ' style={{ backgroundColor: '#494C4F' }} label='Bearbeiten' />
			</div>
			<div className='flex grid'>
				<div className='col flex  flex-column  text-4xl font-semibold'>
					<div className='flex justify-content-center'>
						{currentUser.firstname + " " + currentUser.lastname}
					</div>
				</div>
				<div className='col-fixed w-2rem'>
					<OverlayPanelMenu items={[{ label: 'Passwort ändern', icon: ENUM_ICONS.USER, command: handleChangePassword }]} />
				</div>
			</div>
			<div className='flex card-container blue-container overflow-hidden grid'>
				<FieldInfo label='Telefon' content={currentUser.phonenumber} className='col-3' />
				<FieldInfo label='Mobil' content={currentUser.mobilenumber} className='col-3' />
			</div>
			<div className='flex card-container blue-container overflow-hidden grid'>
				<FieldInfo label='E-Mail' content={currentUser.email} className='col-3' />
				<FieldInfo label='Organisation' content={!currentUser.organization ? '' : currentUser.organization.name} className='col-3' />
			</div>
		</div>
	)
}