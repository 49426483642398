import React, { useContext, useState, useEffect } from "react";
import { ENUM_ICONS } from "../Components/Icons";
import { getData } from '../feathers'
import { LoggerContext } from "../Utils/logger";

const providername = 'ConfigurationsProvider';

const ConfigurationsContext = React.createContext();

const IgnoreInStatisticesList = [
	{ icon: ENUM_ICONS.STATISTICS, display: 'Relevant', datatable: 'Relevant', alias: 0 },
	{ icon: ENUM_ICONS.CROSS + ' text-red-600', display: 'Ignorieren', datatable: 'Ignorieren', alias: 1 }
]
const ServiceList = [{ icon: ENUM_ICONS.COMPANY, onlyIcon: true, datatable: 'Unternehmen', alias: 'companies' }, { icon: ENUM_ICONS.EAACASE, onlyIcon: true, datatable: 'Fall', alias: 'eaa-cases' }, { icon: ENUM_ICONS.JOBPROFILE, onlyIcon: true, datatable: 'Stellenprofil', alias: 'job-openings' }]
const StatusActiveList = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', datatable: 'Aktiv', alias: 1, color: 'green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', datatable: 'Inaktiv', alias: 0, color: 'grey' }];

export function useConfigurationsList() {
	return useContext(ConfigurationsContext);
}

export function ConfigurationsProvider({ children }) {
	const [enumsList, setConfigurationsList] = useState([]);

	useEffect(() => {
		LoggerContext.debug('########### ConfigurationsProvider useEffect');
		getData('configurations').then((_list) => {
			_list.StatusActiveList = StatusActiveList
			_list.IgnoreInStatisticesList = IgnoreInStatisticesList
			_list.ServiceList = ServiceList
			setConfigurationsList(_list);
		}).catch((error) => { LoggerContext.error("Error " + providername, error) });
	}, [])

	return (
		<ConfigurationsContext.Provider value={enumsList}>
			{children}
		</ConfigurationsContext.Provider>
	)
}