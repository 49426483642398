import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { TemplateDataView } from '../Components/TemplateDataView';
import { patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { ENUM_DATAVIEWS } from '../Persistence/DataViewFilterContext';
import { useProviderActivitiesList } from '../Persistence/ProviderActivitiesContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { defaultProviderActivity, ProviderActivitiesForm, validateProviderActivity } from './ProviderActivitiesForm';

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.PROVIDERACTIVITIES

export const ProviderActivitiesDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const currentUser = useCurrentUser();
	const providerActivitiesList = useProviderActivitiesList();
	const [providerActivity, setProviderActivity] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.PRACTIVITY !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const patchProviderActivity = async () => {
		await patchData('provideractivities', formik.values, currentUser).then((provideractivity) => {
			addBreadcrumb(`${provideractivity.name}`, `${ENUM_ROUTES.PROVIDERACTIVITIES}/` + provideractivity.id);
		})
	}
	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "name", sortable: true, filter: "name", filterPlaceholder: "Name suchen", header: "Name der Aktivität", className: '', body: null },
		{ field: "typeActivity", sortable: true, filter: "typeActivity", filterPlaceholder: "Name suchen", header: "Typ", className: 'w-20rem', body: 'renderList' },
		{ field: "creatorname", sortable: true, filter: "creatorname", filterPlaceholder: "Ersteller*in suchen", header: "Ersteller*in", className: '', body: null },
		{ field: "millisecondsDate", sortable: true, filter: "millisecondsDate", header: "Aktivitätsdatum", className: 'w-5rem', body: 'renderDate', },
		{ field: "millisecondsEnddate", sortable: true, filter: "millisecondsEnddate", header: "Wiederkehrend bis", className: 'w-13rem', body: 'renderDate' }
	]

	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return (
		<div className='mx-auto eaa-dataView select-none'>
			<EditDialog
				form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				header={'Neue Aktivität anlegen'}
				message={'Hier können sie eine Aktivität neu anlegen'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayRegionDialog={displayProviderActivityDialog}
			/>

			{!providerActivity ? '' : <ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={providerActivity.name} serviceId={providerActivity.id} serviceName='provideractivities' userId={currentUser.id} />}
			<div className="flex justify-content-end flex-wrap card-container">
				<div className="flex align-self-center justify-content-left  my-2">
					<Button disabled={currentUser.permissions === 'READER'} onClick={() => { formik.setValues({ ...defaultProviderActivity, userId: currentUser.id }); setDisplayProviderActivityDialog(true) }} className='flex  text-white' label='Neue Aktivität anlegen' />
				</div>
			</div>
			<TemplateDataView colums={columns} showOpenLinkColumn={true} dataTableList={providerActivitiesList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES} />
		</div>
	)

}