import { ENUM_ICONS } from "../Components/Icons";
import { logDefault } from "../Utils/logger";

export const RelatedUserCard = ({ relatedUser, onEditHandler, onDeleteHandler, readonly }) => {
	const renderContent = () => {
		const { phonenumber, mobilenumber, email } = relatedUser.user
		return <div className="flex align-content-end flex-column h-5rem px-2 py-1">
			<div className={phonenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col '>{phonenumber}</div></div>
			<div className={mobilenumber ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col '>{mobilenumber}</div></div>
			<div className={email ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col '>{email}</div></div>
		</div>
	}
	const renderTitle = (relatedUser) => {
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{relatedUser.user.lastname + ', ' + relatedUser.user.firstname}
				</div>
				<div>
					{relatedUser.roleType.display}
				</div>
			</div>
			<div className={readonly ? 'hidden' : "flex title-icons justify-content-end font-light"} >
				<i className={ENUM_ICONS.PENCIL + 'title-pencil'} onClick={() => { onEditHandler(relatedUser) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { onDeleteHandler(relatedUser) }} />
			</div>
		</div>
	}

	return (<>
		{!relatedUser || relatedUser.active === 0 ? '' :
			<div id='relateduser-card' className="eaa-card relateduser-card shadow-2">
				<div>{renderTitle(relatedUser)}</div>
				{renderContent(relatedUser)}
			</div>
		}
	</>)
}