import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { getData, patchData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { JobOpeningForm } from "./JobOpeningForm";
import { EAADivider } from "../Components/EAADivider";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { classNames } from "primereact/utils";
import { Tag } from "primereact/tag";
import { JobOpeningSheet } from "./JobOpeningSheet";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { ENUM_ROUTES, ROUTES } from "../Navigation/Routes";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { ENUM_ICONS } from "../Components/Icons";
import { CompanySheet } from "../Companies/CompanySheet";
import exportToPDF from "../Utils/exportToPDF";
import { ToastError } from "../Utils/ToastError";
import { CommentsModule } from "../Components/CommentsModule";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { JobOpeningCaseSheet } from "./JobOpeningCaseSheet";
import { logDefault } from "../Utils/logger";

const numberFormat = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
const boolFormat = (value) => value ? 'Ja' : 'Nein';

export const emptyJobOpeningComment = { id: null, version: 1, active: 1, comment: '' }

const CLASSNAME = 'JobOpeningData';
export const JobOpeningData = () => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const { jobOpeningId } = useParams();
	const [jobOpening, setJobOpening] = useState();
	const [jobOpeningForm, setJobOpeningForm] = useState();
	const [comment, setComment] = useState();
	const [readonly, setReadonly] = useState(true);
	const [jobOpeningComment, setJobOpeningComment] = useState({ ...emptyJobOpeningComment });
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayCommentDialog, setDisplayCommentDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayConfirmDeleteCommentDialog, setDisplayConfirmDeleteCommentDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	useEffect(() => {
		if (jobOpeningId) {
			getJobOpeningData(jobOpeningId)
		}
	}, [jobOpeningId])

	const setJobOpeningFromForm = (jobOpening) => {
		setJobOpeningForm(jobOpening);
	}

	const getJobOpeningData = async (id) => {
		await getData('job-openings', jobOpeningId).then((jobOpening) => {
			jobOpening.jobType = configurationsList.JobOpeningTypeList.find(e => e.alias === jobOpening.jobType);
			jobOpening.status = configurationsList.JobOpeningStatusList.find(e => e.alias === jobOpening.status);
			filterAndSortComments(jobOpening);
			logDefault(CLASSNAME, 'info', 'getJobOpeningData:', jobOpening)
		})
	}

	const filterAndSortComments = (jobOpening) => {
		if (jobOpening.jobOpeningComments) {
			jobOpening.jobOpeningComments = jobOpening.jobOpeningComments.filter(entry => entry.active);
			jobOpening.jobOpeningComments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a == b ? 0 : a > b ? 1 : -1; })
		}
		setJobOpening(jobOpening);
		setReadonly(jobOpening.status.alias === 'INACTIVE' || currentUser.permissions === 'READER')
	}

	const patchJobOpening = async () => {
		jobOpeningForm.eaaCaseId = (jobOpeningForm.eaaCase ? jobOpeningForm.eaaCase.id : jobOpeningForm.eaaCaseId);
		jobOpeningForm.status = jobOpeningForm.status.alias;
		jobOpeningForm.jobType = jobOpeningForm.jobType.alias;
		await patchData('job-openings', jobOpeningForm).then((jobOpening) => {
			setDisplayDialog(false);
			filterAndSortComments(jobOpening);
			setJobOpening(jobOpening);
		})
	}

	const patchJobOpeningComment = async () => {
		jobOpeningComment.comment = comment;
		jobOpeningComment.userId = currentUser.id;
		jobOpeningComment.jobOpeningId = jobOpening.id
		await patchData('job-opening-comments', jobOpeningComment).then(async () => {
			setComment('');
			setJobOpeningComment({ ...emptyJobOpeningComment })
			setDisplayCommentDialog(false);
			await getData('job-openings', jobOpeningId).then(() => {
				getJobOpeningData(jobOpeningId)
			})
		})
	}

	const onDeleteCommentHandler = async () => {
		jobOpeningComment.active = 0;

		await patchData('job-opening-comments', jobOpeningComment).then(async () => {
			setComment('');
			setJobOpeningComment({ ...emptyJobOpeningComment })
			setDisplayConfirmDeleteCommentDialog(false);
			await getData('job-openings', jobOpeningId).then(() => {
				getJobOpeningData(jobOpeningId)
			})
		})
	}

	const handleExportJobOpening = () => {
		exportToPDF('JOBOPENING', jobOpening);
	}
	const handleEditJobOpening = () => {
		setJobOpeningForm({ ...jobOpening });
		setDisplayDialog(true);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const handleStatusJobOpening = async () => {
		const status = { alias: (jobOpening.status.alias === 'INACTIVE' ? 'OPEN' : 'INACTIVE') }
		await patchData('job-openings', { id: jobOpening.id, active: 1, version: jobOpening.version, status }).then(async () => {
			await getJobOpeningData();
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gespeichert werden', message: error.message })
		})
	}

	const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditJobOpening, disabled: readonly },
	{ label: 'Exportieren', icon: ENUM_ICONS.EXPORT, command: handleExportJobOpening },
	{ separator: true },
	{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.TRASH), command: () => { setDisplayConfirmDialog(true) }, disabled: currentUser.permissions === 'READER' }]

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<Dialog header={jobOpeningComment.id ? 'Kommentar ändern' : 'Neuen Kommentar hinzufügen'} visible={displayCommentDialog} onHide={() => setDisplayCommentDialog(false)} id="dialog-jobopeningcomment" className='eaa-inputdialog' >
				<div className='flex w-full my-4'>
					<span className="p-float-label w-full">
						<EditorChangesChecked id='comment' value={comment} setValue={(value) => setComment(value)} label='Beschreibung' setShowDialog={() => { }} />
					</span>
				</div>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCommentDialog(false)} />
					<Button onClick={patchJobOpeningComment} disabled={!jobOpeningComment || jobOpeningComment === ''} className="flex-order-0 ml-3 " label='Speichern' />
				</div>
			</Dialog>

			<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Stellenprofil' name={jobOpening.company.name} serviceId={jobOpening.id} serviceName='job-openings' userId={currentUser.id} />
			<Dialog header='Stellenprofil ändern' visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-jobopening-details" className="eaa-inputdialog" >
				<JobOpeningForm companyId={jobOpening.company.id} jobOpeningId={jobOpeningId} setFormHasErrors={setFormHasErrors} setJobOpening={setJobOpeningFromForm} key={'JobOpeningForm'} setShowDialog={() => { }} />
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayDialog(false)} />
					<Button onClick={patchJobOpening} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Speichern' />
				</div>
			</Dialog>
			<ConfirmDialog title={'Stellenprofil ' + (readonly ? 'reaktivieren' : 'inaktivieren')} message={`Sind Sie sicher, das Stellenprofil zu ${(readonly ? 'reaktivieren' : 'inaktivieren')}`} labelOk='Ja'
				handleOnClick={handleStatusJobOpening} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<ConfirmDialog title='Kommentar entfernen' message={`Sind Sie sicher, Ihren Kommentar vom ${jobOpeningComment.commentDate ? format(parseISO(jobOpeningComment.commentDate), 'yyyy-MM-dd') : ''}\nzu löschen?'`} labelOk='Ja'
				handleOnClick={onDeleteCommentHandler} displayConfirmDialog={displayConfirmDeleteCommentDialog} setDisplayConfirmDialog={setDisplayConfirmDeleteCommentDialog} />

		</>
	}

	const renderContent = () => {
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				{!jobOpening ? '' : <div className='mx-auto eaa-maincontent flex flex-column'>
					<div className="flex flex-column">
						<div className='flex justify-content-end mb-4 gap-2'>
							<Button onClick={handleEditJobOpening} disabled={currentUser.permissions === 'READER'} icon={ENUM_ICONS.PENCIL} className='flex text-white ' style={{ backgroundColor: '#494C4F' }} label='Bearbeiten' />
							<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} disabled={currentUser.permissions === 'READER'} className='flex text-white color-background-lessblack' label='Wiedervorlage' />
						</div>
						<div className='flex grid'>
							<div className='col flex flex-column text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{jobOpening.description}
								</div>
								<div className='flex text-lg justify-content-center my-2 gap-2'>
									<div>{jobOpening ? `${jobOpening.displayId} erstellt am ${format(parseISO(jobOpening.createdAt), 'yyyy-MM-dd')}` : ''}</div>
									<Tag key='tagStatus' className={'mr-2 text-white color-tag-' + jobOpening.status.color} rounded value={jobOpening.status.display} />
								</div>
							</div>
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Unternehmen  ' content={jobOpening.company.name} />
							<FieldInfo label='Fall' content={jobOpening.eaaCase ? jobOpening.eaaCase.name : 'keinem Fall zugeordnet'} />
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Adresse' content={jobOpening.location} className='col-6' />
							<FieldInfo label='Startdatum' content={jobOpening.openingDate ? format(parseISO(jobOpening.openingDate), 'yyyy-MM-dd') : ''} className='col-3' />
							<FieldInfo label='Typ' content={jobOpening.jobType.display} className='col-3' />
						</div>
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Erläuterungen zur Stelle
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: jobOpening.explanation }} />
					</div>

					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule onUpdateComment={getJobOpeningData} key='companyCommentsModule' servicename='job-opening-comments' readonly={readonly} parentId={jobOpening.id} idFieldname='jobOpeningId' comments={jobOpening.jobOpeningComments} />
					</div>

				</div>}
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.JOBPROFILE + "fa-lg", component: <JobOpeningSheet jobOpening={jobOpening} /> },
			{ index: 1, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg", component: <CompanySheet readonly={readonly} company={jobOpening.company} onUpdateCompany={getJobOpeningData} /> },
			{ index: 2, visible: true, icon: ENUM_ICONS.EAACASE + "fa-lg", component: <JobOpeningCaseSheet jobOpening={jobOpening} /> },
		]
		return sideSheets;
	}

	return (!jobOpening ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!jobOpening ? '' : renderContent()}{!jobOpening ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
		/>
	)

}