import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom";
import { AddressesDataview } from './Addresses/AddressesDataview';
import { AddressesForm } from './Addresses/AddressesForm';
import { Application } from './Application';
import { CompaniesData } from './Companies/CompaniesData';
import { CompaniesDataview } from './Companies/CompaniesDataview';
import { Dashboard } from './Dashboard/Dashboard';
import { EAACaseCreate } from './EAACase/EAACaseCreate';
import { EAACaseSheet } from './EAACase/EAACaseData';
import { EAACaseDataview } from './EAACase/EAACaseDataview';
import './index.scss';
import { JobOpeningData } from './JobOpenings/JobOpeningData';
import { JobOpeningDataview } from './JobOpenings/JobOpeningsDataView';
import { ENUM_ROUTES } from './Navigation/Routes';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import { LoginProvider } from './Persistence/LoginContext';
import { ProviderActivitiesDataview } from './ProviderActivities/ProviderActivitiesDataview';
import { ProviderActivitySheet } from './ProviderActivities/ProviderActivitySheet';
import reportWebVitals from './reportWebVitals';
import { RequestDistributionsDataview } from './RequestDistributions/RequestDistributionsDataview';
import { Settings } from './Settings/Settings';
import { Statistic } from './Statistic/Statistic';
import { UserAdministration } from './UserAdministration/UserAdministration';
import { UserProfile } from './UserAdministration/UserProfile';
import { Distribution } from './Utils/Distribution';

const incomingDistributionAction = async ({ request, params }) => {
	console.log('incomingDistributionAction', request, params);
	return (request)
};
const incomingDistributionLoader = async ({ params }) => {
	console.log('incomingDistributionLoader', params)
	return ({ 'incomingDistributionLoader': params });
};

/* const router = createBrowserRouter(
	createRoutesFromElements(
		[<Route path="/distribution/incoming" action={incomingDistributionAction} loader={incomingDistributionLoader} ></Route>,
		<Route path="/" element={<CurrentUserProvider><LoginProvider><Application /></LoginProvider></CurrentUserProvider>}>
			<Route path={`${ENUM_ROUTES.DASHBOARD}`} element={<Dashboard />} />
		</Route>,
		]
	)
); */
const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
/* root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
) */
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<CurrentUserProvider><LoginProvider><Application /></LoginProvider></CurrentUserProvider>}>
					<Route index element={<Dashboard />} />
					<Route path={`${ENUM_ROUTES.DASHBOARD}`} element={<Dashboard />} />
					<Route path={`${ENUM_ROUTES.COMPANIES}`} element={<CompaniesDataview />} />
					<Route path={`${ENUM_ROUTES.COMPANIES}/:companyId`} element={<CompaniesData />} />
					<Route path={`${ENUM_ROUTES.ADDRESSES}`} element={<AddressesDataview />} />
					<Route path={`${ENUM_ROUTES.ADDRESSES}/:addressId`} element={<AddressesForm />} />
					<Route path={`${ENUM_ROUTES.SETTINGS}`} element={<Settings />} />
					<Route path={`${ENUM_ROUTES.EAACASES}`} element={<EAACaseDataview />} />
					<Route path={`${ENUM_ROUTES.EAACASES}/:eaaCaseId`} element={<EAACaseSheet />} />
					<Route path={`${ENUM_ROUTES.EAACASES}/new`} element={<EAACaseCreate />} />
					<Route path={`${ENUM_ROUTES.STATISTICS}`} element={<Statistic />} />
					<Route path={`${ENUM_ROUTES.PROVIDERACTIVITIES}`} element={<ProviderActivitiesDataview />} />
					<Route path={`${ENUM_ROUTES.PROVIDERACTIVITIES}/:provideractivityId`} element={<ProviderActivitySheet />} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}`} element={<JobOpeningDataview />} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}/:jobOpeningId`} element={<JobOpeningData />} />
					<Route path={`${ENUM_ROUTES.REQUESTDISTRIBUTION}`} element={<RequestDistributionsDataview />} />
					<Route path={`${ENUM_ROUTES.USERADMINISTRATION}`} element={<UserAdministration />} />
					<Route path={`${ENUM_ROUTES.USERPROFILE}`} element={<UserProfile />} />
				</Route>

			</Routes>
		</BrowserRouter>
	</React.StrictMode >,
);


reportWebVitals();
