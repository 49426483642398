
export const emptyTaskarea = { id: null, active: 1, version: 1, name: '', tags: [] }
export const TaskareaForm = () => {
	return (
		<>
		</>
	)
}
export const validateTaskarea = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = 'Name ist ein Pflichtfeld';
	}
	return errors;
}