import { classNames } from "primereact/utils";
import { useState, useEffect } from "react";
import { logDefault } from "../../Utils/logger";


const CLASSNAME = 'BasicSheetLayout';
export const BasicSheetLayout = ({ mainContent, sideSheets, overlayMenu }) => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [sideComponent, setSideComponent] = useState(null);

	useEffect(() => {
		const sideSheet = sideSheets ? sideSheets.find(sideSheet => selectedTab === sideSheet.index) : null;
		setSideComponent(sideSheet ? sideSheet.component : null)
		logDefault(CLASSNAME, 'info', 'selectedTab:' + selectedTab, sideSheets)
	}, [selectedTab, mainContent])

	const renderTabSideSheet = (sideSheet) => {
		return !sideSheet.visible ? '' : <li key={'tabSideSheet-' + sideSheet.index}>
			<a onClick={() => setSelectedTab(sideSheet.index)} className={classNames('p-ripple cursor-pointer px-4 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': selectedTab === sideSheet.index, 'text-700 border-transparent': selectedTab !== sideSheet.index })} >
				<i className={sideSheet.icon + " mr-2"}></i>
			</a>
		</li>
	}

	return <div className="flex m-0 w-full h-full ">
		<div className="flex m-0 w-full h-full overflow-y-auto">
			<div className='mx-auto eaa-maincontent '>{mainContent}</div>
		</div>
		{!sideSheets ? '' :
			<div className="flex bg-white eaa-sidecontent h-full">
				<div className="flex flex-column eaa-sidecontent pt-2 overflow-y-auto" >
					<div className='grid m-0 p-0'>
						<div className="col">
							<ul className="card-container pl-3 m-0 list-none flex overflow-x-auto select-none justify-content-start">
								{sideSheets.map(sideSheet => renderTabSideSheet(sideSheet))}
							</ul>
						</div>
						<div className="col-fixed w-2rem flex  align-content-center">
							<div className='flex align-items-center w-full'>
								{overlayMenu}
							</div>
						</div>
					</div>
					<div className="col-fixed eaa-sidecontent">
						{sideComponent}
					</div>
				</div>
			</div>}
	</div>

}