import { FilterMatchMode } from 'primereact/api';
import React, { useContext, useState, useEffect } from "react";
import { useCurrentUser, useCurrentUserIsAdmin } from "./CurrentUserContext";

const DataViewFilterContext = React.createContext();
const DataViewSortContext = React.createContext();
const DataViewFilterUpdateContext = React.createContext();
const DataViewSortUpdateContext = React.createContext();
const DataViewPaginationContext = React.createContext();
const DataViewPaginationUpdateContext = React.createContext();

export const ENUM_DATAVIEWS = { REMINDERS: 'reminders', EAACASES: 'eaacases', COMPANIES: 'companies', JOBOPENINGS: 'jobopenings', PROVIDERACTIVITIES: 'provideractivities', USERADMINISTRATION: 'useradministration', REGIONS: 'regions', ORGANIZATIONS: 'organizations', TASKAREAS: 'taskareas' }

export function useDataViewFilter() {
	return useContext(DataViewFilterContext);
}

export function useDataViewFilterUpdate() {
	return useContext(DataViewFilterUpdateContext);
}

export function useDataViewSort() {
	return useContext(DataViewSortContext);
}

export function useDataViewSortUpdate() {
	return useContext(DataViewSortUpdateContext);
}
export function useDataViewPagination() {
	return useContext(DataViewPaginationContext);
}
export function useDataViewPaginationUpdate() {
	return useContext(DataViewPaginationUpdateContext);
}

export function DataViewFilterProvider({ children }) {
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();

	const responsible = { ...DEFAULT_EAACASES_FILTER.responsible, value: [currentUser.lastname + ', ' + currentUser.firstname] };
	const filterEAA = { ...DEFAULT_EAACASES_FILTER, responsible }
	const [dataViewFilter, setDataViewFilter] = useState({
		eaacases: filterEAA,
		companies: DEFAULT_COMPANIES_FILTER,
		jobopenings: DEFAULT_JOBOPENINGS_FILTER,
		provideractivities: DEFAULT_PROVIDERACTIVITIES_FILTER,
		requestdistributions: DEFAULT_REQUESTDISTRIBUTIONS_FILTER,
		organizations: DEFAULT_ORGANIZATIONS_FILTER,
		regions: DEFAULT_REGIONS_FILTER,
		taskareas: DEFAULT_TASKAREAS_FILTER,
		useradministration: DEFAULT_USERADMINISTRATION_FILTER,
		reminders: DEFAULT_REMINDERS_FILTER
	});

	const [dataViewSort, setDataViewSort] = useState({
		eaacases: { sortField: null, sortOrder: null },
		companies: { sortField: null, sortOrder: null },
		jobopenings: { sortField: null, sortOrder: null },
		provideractivities: { sortField: null, sortOrder: null },
		requestdistributions: { sortField: null, sortOrder: null },
		organizations: { sortField: null, sortOrder: null },
		regions: { sortField: null, sortOrder: null },
		taskareas: { sortField: null, sortOrder: null },
		useradministration: { sortField: null, sortOrder: null },
		reminders: { sortField: null, sortOrder: null },
	});

	const [viewPagination, setViewPagination] = useState({
		eaacases: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		companies: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		jobopenings: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		provideractivities: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		requestdistributions: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		organizations: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		regions: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		taskareas: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		useradministration: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
		reminders: { paginationFirst: 0, paginationRows: 15, currentPage: 0 },
	});

	const setFilter = (ENUM_DATAVIEWS, value) => {
		const filter = { ...dataViewFilter }
		filter[ENUM_DATAVIEWS] = value;
		//console.log('setFilter:' + ENUM_DATAVIEWS, value)
		setDataViewFilter(filter)
	}
	const setSort = (ENUM_DATAVIEWS, value) => {
		const sortItem = { ...dataViewSort }
		sortItem[ENUM_DATAVIEWS] = value;
		//logger.log('setSort:' + ENUM_DATAVIEWS + " - " + "=", value);
		setDataViewSort(sortItem)
	}

	const setPagination = (ENUM_DATAVIEWS, value) => {
		const pagination = { ...viewPagination }
		pagination[ENUM_DATAVIEWS] = value;
		setViewPagination(pagination)
	}

	return (
		<DataViewFilterContext.Provider value={dataViewFilter}>
			<DataViewSortContext.Provider value={dataViewSort}>
				<DataViewPaginationContext.Provider value={viewPagination}>
					<DataViewFilterUpdateContext.Provider value={setFilter}>
						<DataViewSortUpdateContext.Provider value={setSort}>
							<DataViewPaginationUpdateContext.Provider value={setPagination}>
								{children}
							</DataViewPaginationUpdateContext.Provider>
						</DataViewSortUpdateContext.Provider>
					</DataViewFilterUpdateContext.Provider>
				</DataViewPaginationContext.Provider>
			</DataViewSortContext.Provider>
		</DataViewFilterContext.Provider>
	)
}

export const DEFAULT_COMPANIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'region.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Region suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date() },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_EAACASES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Fallname suchen' },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'CaseStatusList', filterElement: 'multiTagFilterElement', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen', maxSelectedLabels: 4 },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'millisecondsCreatedAt': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter', maxDate: new Date() },
	'responsible': { value: null, matchMode: FilterMatchMode.IN, filterPlaceholder: 'Bearbeiter*in suchen', filterElement: 'responsibleFilterElement', filterElementClassName: 'w-10rem' },
}

export const DEFAULT_JOBOPENINGS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'location': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Ort suchen' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Stelle suchen' },
	'status': { value: null, matchMode: FilterMatchMode.IN, filterListName: 'JobOpeningStatusList', filterElement: 'multiTagFilterElement', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen', maxSelectedLabels: 4 },
	'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Unternehmen suchen' },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
	'jobType': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'JobOpeningTypeList', filterElement: 'listFilter', filterElementClassName: 'w-11rem', filterPlaceholder: 'Typ wählen' },
	'millisecondsOpeningDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
}

export const DEFAULT_PROVIDERACTIVITIES_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'typeActivity': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'ProvideractivitiesTypeList', filterElement: 'listFilter', filterElementClassName: 'w-17rem', filterPlaceholder: 'Typ wählen' },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'millisecondsEnddate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'creatorname': { value: null, matchMode: FilterMatchMode.CONTAINS },
}

export const DEFAULT_REQUESTDISTRIBUTIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'subject': { value: null, matchMode: FilterMatchMode.EQUALS, filterPlaceholder: 'Name suchen' },
	'requestDate': { value: null, matchMode: FilterMatchMode.BETWEEN, maxDate: new Date() },
	'sender': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Absender suchen' },
}

export const DEFAULT_ORGANIZATIONS_FILTER = {
	'ignoreInStatistics': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'IgnoreInStatisticesList', filterElement: 'listFilter', filterElementClassName: '', filterPlaceholder: '' },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: 'Name suchen' },
	'type': { value: (null), matchMode: FilterMatchMode.EQUALS, filterListName: 'OrganizationTypesList', filterElement: 'listFilter', filterElementClassName: 'w-20rem', filterPlaceholder: 'Typ wählen' },
}

export const DEFAULT_REGIONS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_TASKAREAS_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'name': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS, filterListName: 'StatusActiveList', filterElement: 'singleTagFilter', filterElementClassName: 'w-10rem', filterPlaceholder: 'Status wählen' },
}

export const DEFAULT_REMINDERS_FILTER = {
	'serviceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterListName: 'ServiceList', filterElement: 'listFilter', filterElementClassName: 'w-4rem' },
	'referenceName': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Name suchen" },
	'millisecondsDate': { value: null, matchMode: FilterMatchMode.BETWEEN, filterElement: 'dateFilter' },
	'description': { value: null, matchMode: FilterMatchMode.CONTAINS, filterPlaceholder: "Notiz suchen" },
}

export const DEFAULT_USERADMINISTRATION_FILTER = {
	'displayId': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'displayname': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'fte': { value: null, matchMode: FilterMatchMode.CONTAINS },
	'active': { value: null, matchMode: FilterMatchMode.EQUALS },
	'permissions': { value: null, matchMode: FilterMatchMode.EQUALS },
	'organization.name': { value: null, matchMode: FilterMatchMode.CONTAINS },

}