import { useCallback, useEffect, useRef } from "react";
import { Toast } from 'primereact/toast';


export const ToastError = ({ toastParams }) => {
	const toast = useRef(null);

	const renderToast = useCallback(() => {
		return !toastParams ? null : {
			life: 10000,
			severity: 'danger', contentClassName: 'bg-red-100 border-3 border-red-600 ', sticky: false, closable: true, content: (
				<div className="flex w-full">
					<div className="flex  flex-column py-auto px-2">
						<i className="pi pi-exclamation-triangle " style={{ fontSize: '3rem' }}></i>
					</div>
					<div className="flex flex-column text-center ">
						<div className='text-sm font-medium'>{toastParams.title}</div>
						<div className='text-sm'>{toastParams.message}</div>
					</div>
				</div>
			)
		}
	}, [toastParams])

	useEffect(() => {
		const toastElement = renderToast();
		if (toastElement !== null) {
			toast.current.show(toastElement);
		}
	}, [toastParams])

	return (<>
		<Toast ref={toast} />
	</>)
}