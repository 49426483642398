import { reactLocalStorage } from 'reactjs-localstorage';
import winston, { createLogger, format, transports } from 'winston';

// Configure the Winston logger. For the complete documentation see https://github.com/winstonjs/winston

winston.addColors({
	verbose: 'magenta',
});

const util = require('util');

function transform(info: any, opts: any) {
	const args = info[Symbol.for('splat')];
	if (args) { info.message = util.format(info.message, ...args); }
	return info;
}

function utilFormatter() { return { transform }; }

export const loglevel = reactLocalStorage.get('loglevel', 'info');
export const loglevellogin = reactLocalStorage.get('loglevellogin', 'info');
export const loglevelcontext = reactLocalStorage.get('loglevelcontext', 'info');

export const logDefault = (classname: string, loglevel: string, message: string, object: any) => {
	const logdefault = reactLocalStorage.get('logdefault', '').split(' ');
	if (logdefault.includes(classname)) {
		object ? logger.log(loglevel, classname + ': ' + message, object) : logger.log(loglevel, message)
	}
}

export const logContext = (classname: string, loglevel: string, message: string, object: any) => {
	const logcontext = reactLocalStorage.get('logcontext', '').split(' ');
	if (logcontext.includes(classname)) {
		LoggerContext.log(loglevel, message, object)
	}
}

const createFormat = (_label: any) => {
	return format.combine(
		format.colorize({ all: true }),
		format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
		format.label({ label: _label }),
		format.splat(),
		utilFormatter(),     // <-- this is what changed
		format.printf((info: any) => `[${info.timestamp}] ${info.label}: ${info.message}`)
	)
}

const createFormatContext = (_label: any) => {
	return format.combine(
		format.colorize({ all: true }),
		format.timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }),
		format.label({ label: _label }),
		format.splat(),
		format.printf((info: any) => `[${info.timestamp}] ${info.label}: ${info.message}`)
	)
}

const logger = createLogger({
	level: loglevel, format: createFormat(''), transports: [new transports.Console({ handleExceptions: true })]
});


export const LoggerContext = createLogger({

	level: loglevelcontext,
	format: createFormat('[C]'),
	transports: [
		new transports.Console({
			format: format.combine(
				format.colorize(),
			)
		})]
});
export const LoggerLogin = createLogger({
	level: loglevellogin, format: createFormat('[L]'), transports: [new transports.Console({ handleExceptions: true })]
});

export default logger;
