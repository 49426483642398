import { useFormik } from 'formik';
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useUserList } from '../Persistence/UserContext';

export const emptyCaseUser = { id: null, version: 1, active: 1, caseuser: null, roleType: '', selectionUser: null }

export const CaseUsersForm = ({ caseUser, setCaseUser, setShowDialog, eaaCase, setFormHasErrors }) => {

	const configurationsList = useConfigurationsList();
	const userList = useUserList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [organization, setOrganization] = useState(null);
	const [caseUserList, setCaseUserList] = useState([]);

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.organizationId === organization.id) : userList;
		if (caseUser && caseUser.id && caseUser.caseuser) {
			setCaseUserList([caseUser.caseuser]);
		} else {
			formik.setFieldValue('caseuser', null)
			setCaseUserList(tempList);
		}
	}, [organization, userList])


	const formik = useFormik({

		initialValues: {
			id: null, version: 1, active: 1, caseuser: null, roleType: '', selectionUser: null
		},
		validate: (data) => {
			let errors = {};

			if (!data.roleType) {
				errors.roleType = 'Verantwortlichkeit muss gesetzt sein';
			}
			if (!data.caseuser) {
				errors.caseuser = 'Person muss gesetzt sein';
			}
			if (data.caseuser && data.roleType && data.roleType.alias === 'R' && eaaCase && eaaCase.caseUsers) {
				const existingCaseUser = eaaCase.caseUsers.find(entry => entry.roleType.alias === data.roleType.alias);
				if (existingCaseUser && existingCaseUser.caseuser.id !== data.caseuser.id)
					errors.roleType = 'Verantwortliche Rolle bereits definiert';
			}
			if (data.caseuser) {
				const existingCaseUser = eaaCase.caseUsers.find(entry => entry.caseuser.id === data.caseuser.id);
				if (existingCaseUser && !caseUser.id)
					errors.caseuser = 'Person hat bereits eine Rolle im Fall';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null

			setFormHasErrors(hasErrors);
			setCaseUser(data);
			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true
	});

	useEffect(() => {
		formik.setValues(caseUser);
	}, [])

	const handleCaseUserChange = (e) => {

		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			formik.setFieldValue('selectionUser', e.value.displayname)
			formik.setFieldValue('caseuser', e.value)
		} else {
			formik.setFieldValue('caseuser', null)
			formik.setFieldValue('selectionUser', e.value)
		}
	}

	return (
		<div className='col'>
			<div className="formgrid grid">
				<div className="field col">
					<div className='card mb-1'><div className="font-bold">Bearbeiter*innen</div> </div>
					<div className={caseUser && eaaCase && eaaCase.id ? 'card' : 'hidden'}>
						<div className={(caseUser.id ? 'hidden' : '') + " field col mr-3 "}>
							<label htmlFor='organization' className='block'>Filter Organisation</label>
							<Dropdown id="organization" showClear filter name="organization" optionLabel="name" value={organization} options={organizationsSelectionList} onChange={(e) => setOrganization(e.value)} autoFocus className={'bg-white w-full'} />
						</div>
						<DropdownChangesChecked filter={true} editmode={!caseUser || !caseUser.id} label={caseUser && caseUser.id ? 'Benutzer bereits in Liste' : 'Benutzer'} id={'caseuser'} itemLabel={'displayname'} formik={formik} options={caseUserList} setShowDialog={setShowDialog} handleChange={formik.handleChange} />
						<DropdownChangesChecked editmode={true} label={'Verantwortlichkeit'} id={'roleType'} itemLabel={'display'} formik={formik} options={configurationsList.CaseRoleList} setShowDialog={setShowDialog} handleChange={handleCaseUserChange} />
					</div>
				</div>
			</div>
		</div>


	)
}
