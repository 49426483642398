import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button";

export const ConfirmDialog = ({ title, message, labelOk, labelCancel, displayConfirmDialog, setDisplayConfirmDialog, handleOnClick, handleOnReject }) => {

	return (<>
		<Dialog header={title} closable={false} visible={displayConfirmDialog} onHide={() => setDisplayConfirmDialog(false)} id="dialog-confirm" className='eaa-inputdialog w-25rem' >
			<div className='flex font-light my-3 p-0 ' style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: message }}>{ }</div>
			<div className="flex flex-row card-container flex-row-reverse mx-4 my-2">
				<Button type="button" className="flex-order-1 button-cancel" label={labelCancel ? labelCancel : 'Abbrechen'} onClick={() => { handleOnReject ? handleOnReject() : setDisplayConfirmDialog(false) }} />
				<Button onClick={() => { handleOnClick(); setDisplayConfirmDialog(false) }} className="flex-order-0 ml-3 " label={labelOk ? labelOk : 'Ok'} />
			</div>

		</Dialog>
	</>)
}
