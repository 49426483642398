import { TemplateDataView } from "../Components/TemplateDataView"
import { ENUM_ROUTES } from "../Navigation/Routes";
import { ENUM_DATAVIEWS } from "../Persistence/DataViewFilterContext"
import { useEAACasesList } from "../Persistence/EAACasesContext";
import { useState, useRef, useEffect, useCallback } from 'react';
import { useJobOpeningsList } from "../Persistence/JobOpeningsContext";

const ENUM_DATAVIEWS_VALUE = ENUM_DATAVIEWS.EAACASES

export const RequestDistributionsDataview = () => {
	const dataTableList = useEAACasesList();

	useEffect(() => {
		//console.log('dataTableList', dataTableList ? dataTableList.length : 0)
	}, [dataTableList])

	const renderResponsible = (rowData) => {
		return (rowData.responsible && typeof rowData.responsible !== 'string' ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : (rowData.responsible ? rowData.responsible : 'n.n.'))
	}

	const columns = [
		{ field: "displayId", sortable: true, filter: "displayId", header: "ID", className: 'w-8rem', body: null },
		{ field: "responsible", sortable: true, filter: "responsible", header: "Berater*in", className: 'w-15rem', body: renderResponsible },
		{ field: "company.name", sortable: true, filter: "company.name", header: "Unternehmen", className: '', body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ field: "name", sortable: true, filter: "name", header: "Name", className: 'w-14rem' },
		{ field: "millisecondsCreatedAt", sortable: true, filter: "millisecondsCreatedAt", header: "Falldatum", className: 'w-5rem', body: 'renderDate' },
		{ field: "status", sortable: true, filter: "status", header: "Status", className: 'w-6rem', body: 'renderTag' }
	]
	return (
		<>
			<div>Aufgabenverteilung</div>
			<TemplateDataView colums={columns} showOpenLinkColumn={true} dataTableList={dataTableList} ENUM_DATAVIEWS_VALUE={ENUM_DATAVIEWS_VALUE} ROUTE={ENUM_ROUTES.PROVIDERACTIVITIES} />
		</>
	)
}